<script setup lang="ts">
import { endOfDay, format as dateFormat } from 'date-fns'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import xirr from 'xirr'
import { useFilter } from '@/modules/core/composables/useFilter'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import CallsBarChart from '@/modules/investing/components/calls-bar-chart.vue'
import DistributionsBarChart from '@/modules/investing/components/distributions-bar-chart.vue'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import { useEntityStore } from '@/modules/investing/stores/better-entity-store'
import {
  VButtonGroup,
  VSection,
  VStats,
  VStatsHero,
  VButton,
  VDescriptionList,
  VSkeletonBar,
} from '@/modules/shared/components'
import { initialMoney, sumMoney, toNumber } from '@/modules/shared/utils/money'
import { dpi, moic, roi, rvpi, tvpi } from '@/modules/shared/utils/reporting'
import { parse, format } from '@/modules/shared/utils/v-table'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { useExtendedI18n } from '@/i18n'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'

///////////////////////////////////////////////////////////////////////////////
// Calls
///////////////////////////////////////////////////////////////////////////////

const workspaceStore = useWorkspaceStore()
const entityStore = useEntityStore()
const entity = computed(() => entityStore.entity)

const { filters } = useFilter({ type: 'sent' })

// NOTE duplicated in calls/index.vue
const filteredCalls = computed(() => {
  return entityStore.listCallsForSelectedInvestors.filter((call) => {
    if (filters.value.type === 'sent' && call.status !== 'sent') {
      return false
    }

    if (filters.value.type === 'scheduled' && call.status !== 'unsent') {
      return false
    }

    return true
  })
})

// NOTE duplicated in calls/index.vue
const callTotals = computed(() => {
  const money = {
    ...initialMoney,
    currency: entityStore.entity?.currency || 'USD',
  }

  return filteredCalls.value.reduce(
    (acc, call) => {
      if (call.is_outstanding) {
        acc.outstanding.called = sumMoney([acc.outstanding.called, call.called])
        acc.outstanding.capital = sumMoney([acc.outstanding.capital, call.capital])
        acc.outstanding.management_fee = sumMoney([acc.outstanding.management_fee, call.management_fee])
        acc.outstanding.other_fee = sumMoney([acc.outstanding.other_fee, call.other_fee])
      }

      if (call.is_settled) {
        acc.settled.called = sumMoney([acc.settled.called, call.called])
        acc.settled.capital = sumMoney([acc.settled.capital, call.capital])
        acc.settled.management_fee = sumMoney([acc.settled.management_fee, call.management_fee])
        acc.settled.other_fee = sumMoney([acc.settled.other_fee, call.other_fee])
      }

      return acc
    },
    {
      outstanding: {
        called: { ...money },
        capital: { ...money },
        management_fee: { ...money },
        other_fee: { ...money },
      },
      settled: {
        called: { ...money },
        capital: { ...money },
        management_fee: { ...money },
        other_fee: { ...money },
      },
    },
  )
})

///////////////////////////////////////////////////////////////////////////////
// Totals
///////////////////////////////////////////////////////////////////////////////

const portfolioTotals = computed(() => {
  return {
    cashPosition: entityStore.getCommitmentTotalsForSelectedInvestors.cash_position,
    currentValue: entityStore.getInvestmentTotalsForSelectedInvestors.currentValue,
    distributed: entityStore.getDistributionTotalsForSelectedInvestors.net,
    initialValue: entityStore.getInvestmentTotalsForSelectedInvestors.initialValue,
    moic: moic({
      current_value: toNumber(entityStore.getInvestmentTotalsForSelectedInvestors.currentValue, true),
      distributed: toNumber(entityStore.getDistributionTotalsForSelectedInvestors.net, true),
      initial_value: toNumber(entityStore.getInvestmentTotalsForSelectedInvestors.initialValue, true),
    }),
    roi: roi({
      current_value: toNumber(entityStore.getInvestmentTotalsForSelectedInvestors.currentValue, true),
      distributed: toNumber(entityStore.getDistributionTotalsForSelectedInvestors.net, true),
      initial_value: toNumber(entityStore.getInvestmentTotalsForSelectedInvestors.initialValue, true),
    }),
  }
})

const statTotals = computed(() => {
  let xirrValue = 0.0

  if (entityStore.listInvestmentsForSelectedInvestors.length > 0) {
    const today = dateFormat(new Date(), 'yyyy-MM-dd')

    const transactions = [
      ...entityStore.listCallsForSelectedInvestors.map((call) => {
        return {
          amount: call.called.amount,
          date: call.due_date,
          type: 'call',
        }
      }),
      ...entityStore.listDistributionsForSelectedInvestors.map((distribution) => {
        return {
          amount: distribution.net.amount,
          date: distribution.date,
          type: 'distribution',
        }
      }),
      {
        amount: sumMoney([portfolioTotals.value.currentValue, portfolioTotals.value.cashPosition]).amount,
        date: today,
        type: 'distribution',
      },
    ]

    const transformedTransactions = transactions
      .map((transaction: any) => {
        const amount = parse(transaction.amount, 'number')
        const actualAmount = transaction.type === 'call' ? -amount : amount
        const date = new Date(transaction.date)

        return {
          amount: actualAmount,
          when: date,
        }
      })
      .filter((a) => new Date(a.when) <= endOfDay(new Date()))
      .sort((a, b) => a.when - b.when)

    xirrValue = xirr(transformedTransactions)
  }

  return {
    dpi: dpi({
      called: toNumber(callTotals.value.settled.called, true),
      distributed: toNumber(entityStore.getDistributionTotalsForSelectedInvestors.net, true),
    }),
    rvpi: rvpi({
      called: toNumber(callTotals.value.settled.called, true),
      current_value: toNumber(portfolioTotals.value.currentValue, true),
    }),
    tvpi: tvpi({
      called: toNumber(callTotals.value.settled.called, true),
      current_value: toNumber(portfolioTotals.value.currentValue, true),
      distributed: toNumber(entityStore.getDistributionTotalsForSelectedInvestors.net, true),
    }),
    xirr: xirrValue,
  }
})

const structure_data = computed(
  (): {
    carried_interest_percent: number
    management_fee_percent: number
    preferred_return_percent: number
  } => {
    const entity_carried_interest_rate = parse(entity.value?.carried_interest_percentage || 0, 'percent') as number
    const entity_management_fee_rate = parse(entity.value?.management_fee_percentage || 0, 'percent') as number
    const entity_preferred_return_rate = parse(entity.value?.preferred_return_percentage || 0, 'percent') as number
    const filtered_commitments = entityStore.listCommitmentsForSelectedInvestors

    if (!filtered_commitments.length) {
      return {
        management_fee_percent: entity_carried_interest_rate,
        carried_interest_percent: entity_management_fee_rate,
        preferred_return_percent: entity_preferred_return_rate,
      }
    }

    const filtered_management_fee_percents = filtered_commitments.map(
      (commitment) => commitment.management_fee_percentage,
    )
    const filtered_carried_interest_percents = filtered_commitments.map(
      (commitment) => commitment.carried_interest_percentage,
    )
    const filtered_preferred_return_percents = filtered_commitments.map(
      (commitment) => commitment.preferred_return_percentage,
    )

    const management_fee_percent = filtered_management_fee_percents[0] || 0
    const carried_interest_percent = filtered_carried_interest_percents[0] || 0
    const preferred_return_percent = filtered_preferred_return_percents[0] || 0

    const display_individual_percents =
      filtered_management_fee_percents.every((percent) => percent === management_fee_percent) &&
      filtered_carried_interest_percents.every((percent) => percent === carried_interest_percent) &&
      filtered_preferred_return_percents.every((percent) => percent === preferred_return_percent)

    if (
      (display_individual_percents && entityStore.listInvestors.length !== entityStore.selectedInvestorKeys.length) ||
      (!!entityStore.selectedInvestorKeys.length && entityStore.listInvestors.length === 1)
    ) {
      return {
        management_fee_percent: management_fee_percent,
        carried_interest_percent: carried_interest_percent,
        preferred_return_percent: preferred_return_percent,
      }
    }

    return {
      management_fee_percent: entity_carried_interest_rate,
      carried_interest_percent: entity_management_fee_rate,
      preferred_return_percent: entity_preferred_return_rate,
    }
  },
)

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entityId, slug } = useRoute().params as { entityId: string; slug: string }

const fetch = async () => {
  await entityStore.fetch(entityId, { slug })
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const isAdmin = computed(() => true)

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { n } = useExtendedI18n()
const { skeleton, hideSkeleton } = useSkeleton()

const waterfall_info = computed(() => {
  switch (entity.value?.waterfall_calculation) {
    case 'american':
      return 'American waterfall is calculated on a deal-by-deal basis, and a GP is compensated for each successful deal. This often allows the GP to begin taking a share of the profits, or carried interest, earlier in the life of a fund.'
    case 'european':
      return "In a European waterfall, 100% of the contributed capital and preferred return is paid out to investors on a pro rata basis before the GP receives any distribution of carried interest. Because it's pro rata, all capital is treated equally, and distributions are paid out in proportion to the amount of capital invested."
    default:
      return null
  }
})

onMounted(async () => {
  await fetch()
  hideSkeleton()
})
</script>

<template>
  <EntityLayout selectedTab="overview" :disableSideSection="false">
    <VSection>
      <VButtonGroup>
        <VSelectInvestor v-model="entityStore.selectedInvestorKeys" :investors="entityStore.listInvestors" />
      </VButtonGroup>
    </VSection>
    <VSection label="360° Portfolio overview">
      <VStatsHero
        class="mb-6"
        :skeleton="skeleton"
        :currentValue="portfolioTotals.currentValue"
        :initialValue="portfolioTotals.initialValue"
        :hiddenFields="[
          !workspaceStore.isDataAccessible('investments.initial_value', isAdmin) ? 'initial' : null,
          !workspaceStore.isDataAccessible('investments.current_value', isAdmin) ? 'current' : null,
        ]"
      />
      <VStats
        :skeleton="skeleton"
        :stats="[
          {
            colspan: 2,
            label: 'ROI',
            type: 'percent',
            value: portfolioTotals.roi,
            visible: workspaceStore.isDataAccessible('investments.roi', isAdmin),
          },
          {
            colspan: 2,
            label: 'MOIC',
            type: 'multiple',
            value: portfolioTotals.moic,
            visible: workspaceStore.isDataAccessible('investments.moic', isAdmin),
          },
          {
            colspan: 2,
            label: 'Cash position',
            type: 'currency',
            value: portfolioTotals.cashPosition,
            visible: workspaceStore.isDataAccessible('investments.cash_position', isAdmin),
          },
          {
            colspan: 2,
            label: 'Distributed',
            type: 'currency',
            value: portfolioTotals.distributed,
            visible: workspaceStore.isDataAccessible('investments.distributions', isAdmin),
          },
        ]"
      />
    </VSection>
    <VSection label="Entity overview">
      <VStats
        class="mb-6"
        :skeleton="skeleton"
        :stats="[
          {
            label: 'XIRR',
            info: `If you see a negative XIRR, but no change in investment value, this could be because of fees paid to execute the investment negatively impacting your return short-term.`,
            type: 'percent',
            value: statTotals.xirr,
          },
          {
            label: 'TVPI',
            type: 'multiple',
            value: statTotals.tvpi,
          },
          {
            label: 'RVPI',
            type: 'multiple',
            value: statTotals.rvpi,
          },
          {
            label: 'DPI',
            type: 'multiple',
            value: statTotals.dpi,
          },
        ]"
      />
      <CallsBarChart
        class="mb-6"
        :commitmentTotals="entityStore.getCommitmentTotalsForSelectedInvestors"
        :callTotals="callTotals"
        :skeleton="skeleton"
      />
      <DistributionsBarChart
        :commitmentTotals="entityStore.getCommitmentTotalsForSelectedInvestors"
        :distributionTotals="entityStore.getDistributionTotalsForSelectedInvestors"
        :skeleton="skeleton"
      />
    </VSection>

    <!-- Side Section -->
    <template #aside>
      <VSection label="General Information">
        <template #actions v-if="isAdmin">
          <a :href="`${rails_url()}/funds/${entityId}/edit#general-information`">
            <VButton size="xs">Edit</VButton>
          </a>
        </template>
        <template #default>
          <VDescriptionList
            v-if="isAdmin && entity?.is_venture360_the_administrator"
            class="mb-1"
            :items="[
              {
                term: 'Billing',
                description: 'View',
              },
            ]"
            :skeleton="skeleton"
          >
            <template #description="{ item }">
              <RouterLink
                :to="{
                  name: 'billing-entity',
                  params: { billable_id: entityId, billable_type: entity.entity_type },
                }"
                class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                v-if="entity"
              >
                {{ item.description }}
              </RouterLink>
            </template>
          </VDescriptionList>
          <VDescriptionList
            v-if="isAdmin && entity?.entity_type === 'fund'"
            class="mb-1"
            :items="[
              {
                term: 'Fund GP',
                description: entity.fund_gp_name,
              },
            ]"
            :skeleton="skeleton"
          />
          <VDescriptionList
            class="mb-4"
            :items="[
              {
                term: 'Managers',
                description: entity?.admins,
              },
            ]"
            :layout="entity?.admins?.length || 0 > 1 ? 'stacked' : 'adjacent'"
            :skeleton="skeleton"
          >
            <template #description="{ item }">
              <span v-for="(admin, index) in entity?.admins" :key="admin.id">
                <RouterLink
                  v-if="isAdmin"
                  class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                  :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
                >
                  {{ admin.name }}
                </RouterLink>
                <span v-else> {{ admin.name }} </span>
                <span v-if="index !== entity.admins.length - 1">, </span>
              </span>
            </template>
          </VDescriptionList>

          <VDescriptionList
            v-if="isAdmin"
            class="mb-4"
            :items="[
              {
                term: 'Type',
                description: entity?.type,
              },
              {
                term: 'Date Established',
                description: entity?.date_established ? dateFormat(new Date(entity.date_established), 'PP') : null,
              },
              {
                term: 'EIN',
                description: entity?.tax_id,
              },
              {
                term: 'Currency',
                description: entity?.currency,
              },
            ]"
            :skeleton="skeleton"
          />
          <VDescriptionList
            v-if="isAdmin"
            :items="[{ term: 'Address', description: entity?.address }]"
            layout="stacked"
          >
            <template #description="{ item }">
              <VSkeletonBar v-if="skeleton" />
              <div v-else>
                <div>{{ item.description?.line1 }}</div>
                <div v-if="!!item.description?.line2">{{ item.description?.line2 }}</div>
                <div>
                  {{ item.description?.city }} {{ item.description?.state }}
                  {{ item.description?.postal_code }}
                </div>
                <div>{{ item.description?.country }}</div>
              </div>
            </template>
          </VDescriptionList>
        </template>
      </VSection>
      <VSection label="Structure">
        <template #actions v-if="isAdmin">
          <a :href="`${rails_url()}/funds/${entityId}/edit#structure`">
            <VButton size="xs">Edit</VButton>
          </a>
        </template>
        <template #default>
          <VDescriptionList
            :items="[
              {
                term: 'Management fee',
                description: n(structure_data.management_fee_percent, 'percent'),
              },
              {
                term: 'Carried interest',
                description: n(structure_data.carried_interest_percent, 'percent'),
              },
              {
                term: 'Preferred return',
                description: n(structure_data.preferred_return_percent, 'percent'),
              },
            ]"
            :skeleton="skeleton"
          />
          <VDescriptionList
            v-if="entity?.entity_type === 'fund'"
            class="mt-4"
            :items="[
              {
                term: 'Capital Call Frequency',
                description: entity?.capital_call_frequency || '--',
              },
              {
                term: 'Investment Period',
                description: entity?.investment_period ? `${entity.investment_period} years` : '--',
              },
              {
                term: 'Interest Catch-up Provision',
                description: format(entity.has_interest_catch_up_provision, 'boolean'),
              },
              {
                term: 'Waterfall',
                description: entity?.waterfall_calculation || '--',
                info: waterfall_info,
              },
            ]"
            :skeleton="skeleton"
          />
        </template>
      </VSection>
    </template>
  </EntityLayout>
</template>
