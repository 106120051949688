import { ref } from 'vue'

export const useFilter = (definedFilters: Record<string, any>) => {
  const filters = ref(definedFilters)

  const setFilter = (key: string, value: unknown) => {
    filters.value = {
      ...filters.value,
      [key]: value,
    }
  }

  return {
    filters,
    setFilter,
  }
}
