<script setup lang="ts">
import { CompanyExpanded, IndustryExpanded } from '@/modules/investing/stores/better-entity-store'
import { VSection, VTabListSimple } from '@/modules/shared/components'
import AllocationChart from '@/modules/shared/components/allocation-chart.vue'
import PerformerChart from '@/modules/shared/components/performer-chart.vue'
import { computed, ref } from 'vue'
import { roi } from '@/modules/shared/utils/reporting'
import { sumMoney, toNumber } from '@/modules/shared/utils/money'

const props = defineProps<{
  companies: CompanyExpanded[]
  industries: IndustryExpanded[]
  skeleton: boolean
}>()

const tabIndex = ref(0)

const topPerformers = computed(() =>
  props.companies
    .filter((company) => company.roi > 0)
    .sort((a, b) => b.roi - a.roi)
    .slice(0, 10),
)
const getTotal = (key: string) => toNumber(sumMoney(props.companies.map((company) => company[key])))
const totalROI = computed(() =>
  roi({
    current_value: getTotal('current_value'),
    distributed: getTotal('distributed'),
    initial_value: getTotal('initial_value'),
  }),
)
</script>

<template>
  <VSection class="sm:w-1/2">
    <VTabListSimple
      @update:current="(index: number) => (tabIndex = index)"
      :current="tabIndex"
      :tabs="['Portfolio Allocation', 'Industry Allocation', 'Top Performers']"
      class="-mt-2"
    />
    <div class="mt-6 rounded-lg border border-gray-200 bg-white p-2 sm:p-6">
      <AllocationChart v-if="tabIndex === 0" :items="companies" :skeleton="skeleton" />
      <AllocationChart v-if="tabIndex === 1" :items="industries" :skeleton="skeleton" />
      <PerformerChart v-if="tabIndex === 2" :items="topPerformers" :skeleton="skeleton" :total_roi="totalROI" />
    </div>
  </VSection>
</template>
