import { ref } from 'vue'

export const useSkeleton = () => {
  const skeleton = ref(true)

  const showSkeleton = () => {
    skeleton.value = true
  }

  const hideSkeleton = () => {
    skeleton.value = false
  }

  return {
    skeleton,
    showSkeleton,
    hideSkeleton,
  }
}
