import { useRoute } from 'vue-router'

// prettier-ignore
const rails_url = (slug=null) => {
  const route = useRoute()
  slug = slug || route?.params?.slug

  return import.meta.env.VITE_VENTURE360_API_URL
    .replace('://api.', '://' + slug + '.')
    .replace('/v3', '')
}

export { rails_url }
