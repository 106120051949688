<script setup lang="ts">
import Big from 'big.js'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
import { computed, ref } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { VIcon, VSkeletonBar } from '@/modules/shared/components'
import { Money, initialMoney, sum } from '@/modules/shared/utils/money'
import { colors } from '../utils/chart'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'

ChartJS.register(ArcElement, Tooltip, Legend)

const props = defineProps<{
  items: { id: string | number; name: string; initial_value: Money; current_value: Money }[]
  isAdmin?: boolean
  skeleton: boolean
}>()

const { n } = useExtendedI18n()
const workspaceStore = useWorkspaceStore()
const selected_item = ref(null)

// Chart
const initialValueChart = ref(null)
const currentValueChart = ref(null)

const initialValueChartData = computed(() => {
  const initial_values = props.items.map((item) => new Big(item.initial_value.common_amount).toNumber())
  return {
    labels: props.items.map((item) => item.name),
    datasets: [
      {
        label: 'Initial Value',
        data: initial_values.length === 0 || parseFloat(total_initial_value.value.amount) === 0 ? [1] : initial_values,
        backgroundColor: colors,
        hoverOffset: 10,
      },
    ],
  }
})

const currentValueChartData = computed(() => {
  const current_values = props.items.map((item) => new Big(item.current_value.common_amount).toNumber())
  return {
    labels: props.items.map((item) => item.name),
    datasets: [
      {
        label: 'Current Value',
        data: current_values.length === 0 || parseFloat(total_current_value.value.amount) === 0 ? [1] : current_values,
        backgroundColor: colors,
        hoverOffset: 10,
      },
    ],
  }
})

const total_initial_value = computed(() => sum(props.items.map((item) => item.initial_value)) || initialMoney)
const total_current_value = computed(() => sum(props.items.map((item) => item.current_value)) || initialMoney)

function syncHover(chart, index) {
  if (!chart) return
  chart.setActiveElements([
    {
      datasetIndex: 0,
      index: index,
    },
  ])
  chart.tooltip.setActiveElements([
    {
      datasetIndex: 0,
      index: index,
    },
  ])
  chart.update()
}

function removeHover(chart) {
  if (!chart) return
  chart.setActiveElements([])
  chart.tooltip.setActiveElements([])
  chart.update()
}

const options = computed(() => ({
  layout: {
    padding: 5,
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutout: '75%',
  radius: '100%',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  onHover: (event, chartElement) => {
    if (chartElement.length > 0) {
      const index = chartElement[0].index
      selected_item.value = props.items[index]
      syncHover(initialValueChart.value?.chart, index)
      syncHover(currentValueChart.value?.chart, index)
    } else {
      removeHover(initialValueChart.value?.chart)
      removeHover(currentValueChart.value?.chart)
      selected_item.value = null
    }
  },
}))
</script>

<template>
  <div>
    <div class="text-center text-lg font-medium sm:-mb-5">
      <VSkeletonBar v-if="skeleton" />
      <template v-else>{{ selected_item?.name || 'All' }}</template>
    </div>
    <div class="flex flex-col items-center sm:flex-row sm:items-center sm:justify-around">
      <div
        class="relative w-[70%] sm:-mb-10 sm:w-[220px]"
        v-if="workspaceStore.isDataAccessible('investments.initial_value', isAdmin)"
      >
        <Doughnut
          @mouseleave="removeHover(initialValueChart.chart)"
          :data="initialValueChartData"
          :options="options"
          ref="initialValueChart"
        />
        <div class="pointer-events-none absolute inset-0 flex flex-col items-center justify-center">
          <div class="mb-1 text-sm font-medium text-gray-700">Initial Value</div>
          <VSkeletonBar v-if="skeleton" />
          <div class="font-bold text-gray-800" v-else>
            {{ n(selected_item?.initial_value || total_initial_value, 'currency', null, true, 0) }}
          </div>
        </div>
      </div>
      <template v-if="workspaceStore.isDataAccessible('investments.initial_value', isAdmin)">
        <VIcon name="arrow_narrow_right" class="hidden h-8 w-8 sm:-mb-10 sm:block" />
        <VIcon name="arrow_narrow_right" class="block h-10 w-10 rotate-90 sm:hidden" />
      </template>
      <div class="relative w-[70%] sm:-mb-10 sm:w-[220px]">
        <Doughnut
          @mouseleave="removeHover(currentValueChart.chart)"
          :data="currentValueChartData"
          :options="options"
          ref="currentValueChart"
        />
        <div class="pointer-events-none absolute inset-0 flex flex-col items-center justify-center">
          <div class="mb-1 text-sm font-medium text-gray-700">Current Value</div>
          <VSkeletonBar v-if="skeleton" />
          <div class="font-extrabold text-gray-800" v-else>
            {{ n(selected_item?.current_value || total_current_value, 'currency', null, true, 0) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
