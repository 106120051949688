<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { VSkeletonBar, VIcon } from '@/modules/shared/components'
import { type Money } from '@/modules/shared/utils/money'
import { computed } from 'vue'

const props = defineProps<{
  skeleton?: boolean
  currentValue: Money
  initialValue: Money
  hiddenFields?: ('initial' | 'current')[]
}>()

const { n } = useExtendedI18n()

const isInitialVisible = computed(() => !props.hiddenFields?.includes('initial'))
const isCurrentVisible = computed(() => !props.hiddenFields?.includes('current'))
</script>

<template>
  <div
    class="flex flex-grow items-center justify-center gap-6 rounded-lg bg-[#3D8A9D] bg-opacity-10 py-4 dark:border-[#161618] dark:bg-[#1B1B1F] sm:gap-10 sm:py-8"
  >
    <div class="text-center" v-if="isInitialVisible">
      <p class="sm:text-basee mb-1 whitespace-nowrap text-sm font-medium text-gray-700">Initial value</p>
      <h3 class="text-lg font-medium tracking-tight text-[#336f8f] sm:text-2xl">
        <span v-if="skeleton"><VSkeletonBar class="inline-block" /></span>
        <span v-else>{{ n(initialValue, 'currency', null, true) }}</span>
      </h3>
    </div>
    <VIcon class="w-8 text-[#336f8f]" name="arrow_narrow_right" v-if="isInitialVisible" />
    <div class="text-center" v-if="isCurrentVisible">
      <p class="sm:text-basee mb-1 whitespace-nowrap text-sm font-medium text-gray-700">Current value</p>
      <h3 class="text-lg font-medium tracking-tight text-[#336f8f] sm:text-2xl">
        <span v-if="skeleton"><VSkeletonBar class="inline-block" /></span>
        <span v-else>{{ n(currentValue, 'currency', null, true) }}</span>
      </h3>
    </div>
  </div>
</template>
