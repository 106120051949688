<script setup lang="ts">
import { get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter, RouterLink } from 'vue-router'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import { useFetch } from '@/modules/core/stores/fetch'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import { useEntityStore } from '@/modules/investing/stores/better-entity-store'
import {
  ActionsGroup,
  ActionsMenu,
  ActionItem,
  VButtonGroup,
  VIcon,
  VSection,
  VTable,
  VModal,
} from '@/modules/shared/components'
import { sumMoney, initialMoney } from '@/modules/shared/utils/money'
import { rails_url } from '@/modules/shared/utils/rails'
import { distributionMethodColumns } from '@/modules/shared/utils/columns/distributionMethod'
import { format } from '@/modules/shared/utils/v-table'
import DistributionsBarChart from '@/modules/investing/components/distributions-bar-chart.vue'
import { useModal } from '@/modules/shared/composables/use-modal'
import EntityDistributionMarkAsPaidForm from '@/modules/investing/components/entities/distributions/markAsPaidForm.vue'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'
import { VButton } from '@/modules/shared/components'

///////////////////////////////////////////////////////////////////////////////
// Distributions
///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()
const entity = computed(() => entityStore.entity)

const expandedDistributions = computed(() => {
  return entityStore.listDistributionsForSelectedInvestors.map((distribution) => {
    return {
      ...distribution,
      company: entityStore.getCompany(distribution.company_id),
      investor: entityStore.getInvestor(distribution.investor_id, distribution.investor_type),
    }
  })
})

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entityId, slug } = useRoute().params as { entityId: string; slug: string }
const slideInFormModal = useModal()
const selected_distribution = ref(null)

const deleteDistribution = async (distributionId: string) => {
  if (!window.confirm('Are you sure?')) return
  await useFetch(`/${slug}/investing/b/entities/${entityId}/distributions/${distributionId}/remove`).post()
  await fetch()
}

const fetch = async () => {
  await entityStore.fetch(entityId, { slug })
}

const markPaymentAsUnpaid = async (distributionId: string) => {
  await useFetch(
    `/${slug}/investing/b/entities/${entityId}/distributions/${distributionId}/mark-payment-as-unpaid`,
  ).post()
  await fetch()
}

const openPayDistribution = (distributionId: string) => {
  selected_distribution.value = entityStore.getDistribution(distributionId)
  slideInFormModal.open()
}

const payDistribution = async (payload: { id: string; paid_date: string }) => {
  await useFetch(`/${slug}/investing/b/entities/${entityId}/distributions/${payload.id}/mark-payment-as-paid`).post({
    distribution: payload,
  })
  await fetch()

  slideInFormModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const isAdmin = computed(() => true)

///////////////////////////////////////////////////////////////////////////////
// Rails Utils
///////////////////////////////////////////////////////////////////////////////

const createCompanyUrl = (id: string) => {
  return `${rails_url()}/companies/${id}`
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const router = useRouter()
const { skeleton, hideSkeleton } = useSkeleton()

onMounted(async () => {
  await fetch()
  hideSkeleton()
})
</script>

<template>
  <EntityLayout selectedTab="distributions">
    <VSection class="flex items-center justify-between">
      <VButtonGroup>
        <VSelectInvestor v-model="entityStore.selectedInvestorKeys" :investors="entityStore.listInvestors" />
      </VButtonGroup>
      <RouterLink
        :to="{
          name: 'investing.disbursement.new',
          params: { entity_type: entity?.entity_type, entity_id: entity?.id },
        }"
        v-if="entity"
      >
        <VButton size="md" variant="v-blue">
          <div class="mr-1 flex items-center space-x-2">
            <div><VIcon name="plus" /></div>
            <div>Distribution</div>
          </div>
        </VButton>
      </RouterLink>
    </VSection>
    <VSection>
      <DistributionsBarChart
        :commitmentTotals="entityStore.getCommitmentTotalsForSelectedInvestors"
        :distributionTotals="entityStore.getDistributionTotalsForSelectedInvestors"
        :skeleton="skeleton"
      />
    </VSection>
    <VSection>
      <VTable
        :columns="[
          {
            key: 'investor.name',
            name: 'Investor',
            type: 'string',
            align: 'left',
            fixed: true,
            is_visible: true,
          },
          {
            key: 'date',
            name: 'Date',
            sorted: true,
            type: 'date',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'type',
            name: 'Type',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'company.name',
            name: 'Company',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'is_recallable',
            name: 'Recallable',
            type: 'boolean',
            align: 'center',
            is_visible: false,
          },
          {
            key: 'net',
            name: 'Net Distributed',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'return_of_capital',
            name: 'Return of Capital',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'preferred_return',
            name: 'Preferred Return',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'profit',
            name: 'Profit',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'carried_interest',
            name: 'Carried Interest',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'other_fee',
            name: 'Other Fee',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'gross',
            name: 'Gross Distributed',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'payment_method',
            name: 'Payment Method',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'payment_status',
            name: 'Payment Status',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          ...distributionMethodColumns,
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_visible: true,
          },
        ]"
        :items="expandedDistributions"
        :name="`entities-${1}-distributions`"
        :skeleton="skeleton"
      >
        <template #investor.name="{ item: distribution }">
          {{ get(distribution, 'investor.name') }}
        </template>
        <template #company.name="{ item: distribution }">
          <a class="hyperlink" :href="createCompanyUrl(distribution.company_id)">
            {{ get(distribution, 'company.name') }}
          </a>
        </template>
        <template #payment_status="{ item: distribution }">
          {{ `${distribution.payment_status} ${format(distribution.payment_status_marked_at || undefined, 'data')}` }}
        </template>
        <template #actions="{ item: distribution }">
          <ActionsMenu v-if="distribution.type !== 'transfer distribution'">
            <ActionsGroup>
              <ActionItem
                @click="
                  () =>
                    router.push({
                      name: 'investing.disbursement.edit',
                      params: {
                        entity_type: 'entities',
                        entity_id: entityId,
                        disbursement_id: get(distribution, 'transaction.id'),
                      },
                    })
                "
                text="Edit"
                tag="a"
              />
              <ActionItem
                v-if="distribution.payment_status === 'paid'"
                text="Mark as Pending"
                @click="markPaymentAsUnpaid(distribution.id)"
              />
              <ActionItem v-else text="Mark as Paid" @click="openPayDistribution(distribution.id)" />
              <ActionItem text="Remove" @click="deleteDistribution(distribution.id)" />
            </ActionsGroup>
          </ActionsMenu>
        </template>
      </VTable>
      <VModal :modalStore="slideInFormModal" :has_footer="false">
        <template #main>
          <EntityDistributionMarkAsPaidForm :onSubmit="payDistribution" :distribution="selected_distribution" />
        </template>
      </VModal>
    </VSection>
  </EntityLayout>
</template>
