<script setup lang="ts">
import { computed, ref } from 'vue'
import { type Key } from '@/modules/core/stores/kv'
import { useEntityStore, type Commitment, type Call } from '@/modules/investing/stores/better-entity-store'

///////////////////////////////////////////////////////////////////////////////
// mock data
///////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////
// tmp
///////////////////////////////////////////////////////////////////////////////

const sum = <T,>(items: T[], key: string) => {
  return items?.reduce((acc, call) => acc + Number(call[key]), 0)
}

///////////////////////////////////////////////////////////////////////////////
// main
///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()

const selectedInvestorKeys = ref<Key[]>([])

const committed = computed(() =>
  sum<Commitment>(entityStore.listCommitmentsForInvestors(selectedInvestorKeys.value), 'committed'),
)
const called = computed(() => sum<Call>(entityStore.listCallsForInvestors(selectedInvestorKeys.value), 'called'))

const fetch = async () => {
  const entityId = '6701' // '6701' // '10262' // '12066'
  await entityStore.fetch(entityId, { slug: 'test' })
}
</script>

<template>
  <div>
    <button @click="fetch">Fetch</button>
  </div>

  <div>
    <select v-model="selectedInvestorKeys" multiple>
      <option v-for="investor in entityStore.entity?.investors" :value="[investor.id, investor.type]">
        {{ investor.name }}
      </option>
    </select>
  </div>

  {{ selectedInvestorKeys }}

  <div>
    <table>
      <tr>
        <th>Committed</th>
        <th>Called</th>
      </tr>
      <tr>
        <td>{{ committed }}</td>
        <td>{{ called }}</td>
      </tr>
    </table>
  </div>

  <div>
    <h3 class="text-xl">Commitments</h3>
    <table>
      <tr>
        <th>Investor</th>
        <th>Committed</th>
      </tr>
      <tr v-for="commitment in entityStore.listCommitmentsForInvestors(selectedInvestorKeys)">
        <td>
          {{ entityStore.getInvestorByCommitment(commitment).name }}
        </td>
        <td>{{ commitment.committed }}</td>
      </tr>
    </table>
  </div>

  <div>
    <h3 class="text-xl">Calls</h3>
    <table>
      <tr>
        <th>Investor</th>
        <th>Called</th>
        <th>Capital</th>
        <th>Management Fee</th>
        <th>Other Fee</th>
      </tr>
      <tr v-for="call in entityStore.listCallsForInvestors(selectedInvestorKeys)">
        <td>
          {{ entityStore.getInvestorByCall(call).name }}
        </td>
        <td>{{ call.called }}</td>
        <td>{{ call.capital }}</td>
        <td>{{ call.management_fee }}</td>
        <td>{{ call.other_fee }}</td>
      </tr>
    </table>
  </div>

  <div>
    <h3 class="text-xl">Distributions</h3>
    <table>
      <tr>
        <th>Investor</th>
        <th>Distributed</th>
      </tr>
      <tr v-for="distribution in entityStore.listDistributionsForInvestors(selectedInvestorKeys)">
        <td>
          {{ entityStore.getInvestorByDistribution(distribution).name }}
        </td>
        <td>{{ distribution.gross }}</td>
      </tr>
    </table>
  </div>

  <div>
    <h3 class="text-xl">Investments</h3>
    <table>
      <tr>
        <th>Investor</th>
        <th>Initial Value</th>
        <th>Current Value</th>
      </tr>
      <tr v-for="investment in entityStore.listInvestmentsForInvestors(selectedInvestorKeys)">
        <td>
          {{ entityStore.getInvestorByInvestment(investment).name }}
        </td>
        <td>{{ investment.initial_value }}</td>
        <td>{{ investment.current_value }}</td>
      </tr>
    </table>
  </div>

  <!-- <table>
    <tr>
      <th>Investor Name</th>
      <th>Commitment</th>
    </tr>
    <tr v-for="entry in entityInvestorStore.selectedEntries">
      <td>{{ entry.value.name }}</td>
      <td>{{ entry.value.committed }}</td>
    </tr>
  </table> -->

  <!-- <table>
    <tr>
      <th>Investor Name</th>
      <th>Due Date</th>
      <th>Capital</th>
      <th>Management Fee</th>
      <th>Other Fee</th>
      <th>Total</th>
    </tr>
    <tr v-for="call in entityCallStore.selectedEntries">
      <td>{{ entityInvestorStore.kv.get(call.investorCid).name }}</td>
      <td>{{ call.due }}</td>
      <td>{{ call.capital }}</td>
      <td>{{ call.managementFee }}</td>
      <td>{{ call.otherFee }}</td>
      <td>{{ call.called }}</td>
    </tr>
  </table> -->
</template>
