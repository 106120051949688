<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { format as formatDateFNS } from 'date-fns'
import { capitalize, startCase as _startCase } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { requestPasswordResetService } from '@/modules/auth/api'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { VSection, TheContent, VDescriptionList, VButton, VIcon, VSkeletonBar } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import IndividualHeader from '@/modules/investing/components/individual-header.vue'
import IndividualNav from '@/modules/investing/components/individual-nav.vue'
import { format } from '@/modules/shared/utils/v-table'
import { rails_url } from '@/modules/shared/utils/rails'
import { useBeneficiaryStore } from '../stores/beneficiary-store'
import { useContactStore } from '../stores/contact-store'
import {
  AccreditedStatus,
  ProfileStatus,
  useInvestingInvestorStore,
  disbursementMethodInfo,
  DisbursementMethod,
} from '../stores/investor-store'

function startCase(str: string) {
  return str
    .split(' / ')
    .map((part) => _startCase(part))
    .join(' / ')
}

const { n, t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()

const cid = computed(() => `individual:${route.params.individual_id}`)
const authStore = useAuthStore()
const investorStore = useInvestingInvestorStore()
const beneficiaryStore = useBeneficiaryStore()
const contactStore = useContactStore()
const skeleton = ref(true)
const currentIndividual = ref(null)
const isAdmin = computed(
  () =>
    authStore.is_site_or_group_admin || authStore.current_user?.investor_id?.toString() === route.params.individual_id,
)

const is_current_individual_viewing = computed(
  () => authStore.current_user?.investor_id?.toString() === route.params.individual_id,
)
const any_new_membership_agreement = computed(
  () => currentIndividual.value?.membership_agreement_data?.group_agreement?.is_new,
)

const is_profile_complete = computed(() => currentIndividual.value?.profile_status === ProfileStatus.Complete)
const is_accredited = computed(() => currentIndividual.value?.accredited_status === AccreditedStatus.Accredited)

const request_password_reset = async () => {
  if (!window.confirm('Are you sure?')) return

  const payload = {
    email: currentIndividual.value.email,
  }
  const res = await requestPasswordResetService(payload)
  const { status } = res

  if (status == 'success') {
    alert('Thanks, check your email for instructions to reset your password')
    if (!authStore.is_site_or_group_admin) router.push({ name: 'logout' })
  }
}

const sendRegistrationInvitation = async () => {
  await investorStore.sendRegistrationInvitationToIndividual(currentIndividual.value.id)
  alert(`${currentIndividual.value.name} has been sent a link to register`)
}

onMounted(async () => {
  await Promise.all([
    investorStore.fetchIndividual(route.params.individual_id),
    beneficiaryStore.fetchBeneficiaries(),
    contactStore.fetchContacts(),
  ])
  currentIndividual.value = investorStore.items.get(`individual:${route.params.individual_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <IndividualHeader />
    <IndividualNav />

    <TheContent :disableSideSection="true">
      <template #main>
        <!-- <div class="-mt-7 mb-8 flex items-center justify-center gap-6 rounded border-[1px] border-gray-300 p-3 text-sm">
          <div class="flex items-center gap-2">
            <div
              class="h-4 w-4 rounded-full border-[1px] border-[#3b88af] p-0.5"
              :class="{ 'bg-[#3b88af]': is_profile_complete }"
            >
              <VIcon name="check" class="text-white" v-if="is_profile_complete" />
            </div>
            {{ startCase(t('shared.account information')) }}
          </div>
          <div
            class="h-[2px] w-[70px]"
            :class="{ 'bg-[#3b88af]': is_profile_complete, 'bg-gray-300': !is_profile_complete }"
          ></div>
          <div class="flex items-center gap-2">
            <div
              class="h-4 w-4 rounded-full border-[1px] border-[#3b88af] p-0.5"
              :class="{ 'bg-[#3b88af]': is_accredited }"
            >
              <VIcon name="check" class="text-white" v-if="is_accredited" />
            </div>
            {{ capitalize(t('shared.accreditation')) }}
          </div>
          <div class="h-[2px] w-[70px]" :class="{ 'bg-[#3b88af]': is_accredited, 'bg-gray-300': !is_accredited }"></div>
          <div class="flex items-center gap-2">
            <div class="h-4 w-4 rounded-full border-[1px] border-[#3b88af] p-0.5" :class="{ 'bg-[#3b88af]': false }">
              <VIcon name="check" class="text-white" v-if="false" />
            </div>
            {{ startCase(t('shared.investment preference', 0)) }}
          </div>
        </div> -->
        <div class="mb-8 flex gap-8">
          <VSection
            label="Personal Information"
            label_class="!font-semibold text-lg mb-3"
            class="!mb-0 w-1/2 space-y-5 rounded border-[1px] border-gray-300 p-3"
          >
            <template #actions v-if="isAdmin">
              <RouterLink :to="{ name: 'investing.individual.edit', hash: '#general-information' }">
                <VButton size="xs" variant="v-white" class="mb-2">
                  {{ capitalize(t('shared.edit')) }}
                </VButton>
              </RouterLink>
            </template>
            <VDescriptionList
              :items="[
                {
                  term: capitalize(t('shared.first name')),
                  description: currentIndividual?.first_name,
                },
                {
                  term: capitalize(t('shared.last name')),
                  description: currentIndividual?.last_name,
                },
                {
                  term: capitalize(t('shared.email')),
                  description: currentIndividual?.email,
                },
                {
                  term: capitalize(t('shared.phone')),
                  description: currentIndividual?.phone,
                },
                {
                  term: capitalize(t('shared.street')),
                  description: currentIndividual?.address?.line1,
                },
                {
                  term: capitalize(t('shared.city')),
                  description: currentIndividual?.address?.city,
                },
                {
                  term: startCase(t('shared.state / province')),
                  description: currentIndividual?.address?.state,
                },
                {
                  term: startCase(t('shared.zip / postal code')),
                  description: currentIndividual?.address?.postal_code,
                },
                {
                  term: capitalize(t('shared.country')),
                  description: currentIndividual?.address?.country,
                },
                {
                  term: capitalize(t('shared.birthdate')),
                  description: currentIndividual?.birthdate ? format(currentIndividual.birthdate, 'date') : null,
                },
              ]"
              :skeleton="skeleton"
              item_class="!mb-5"
              theme="form"
            />
          </VSection>
          <VSection
            label="Disbursement Preferences"
            label_class="!font-semibold text-lg mb-3"
            class="!mb-0 w-1/2 rounded border-[1px] border-gray-300 p-3"
          >
            <template #actions v-if="isAdmin">
              <RouterLink :to="{ name: 'investing.individual.edit', hash: '#disbursement-preferences' }">
                <VButton size="xs" variant="v-white" class="mb-2">
                  {{ capitalize(t('shared.edit')) }}
                </VButton>
              </RouterLink>
            </template>
            <VDescriptionList
              :items="[
                {
                  term: capitalize(t('shared.how would you like to receive disbursements?')),
                  description: disbursementMethodInfo[currentIndividual?.disbursement_method]?.label,
                },
              ]"
              :skeleton="skeleton"
              class="mb-5"
              theme="form"
            />
            <VDescriptionList
              :items="[
                {
                  term: capitalize(t('shared.bank name')),
                  description: currentIndividual?.disbursement_wire_bank_name,
                },
                {
                  term: capitalize(t('shared.bank account number')),
                  description: currentIndividual?.disbursement_wire_bank_account_number,
                },
                {
                  term: capitalize(t('shared.for further credit to')),
                  description: currentIndividual?.disbursement_wire_for_further_credit_to,
                },
                {
                  term: capitalize(t('shared.bank address')),
                  description: currentIndividual?.disbursement_wire_bank_address,
                },
                {
                  term: capitalize(t('shared.bank swift code')),
                  description: currentIndividual?.disbursement_wire_bank_swift_code,
                },
                {
                  term: capitalize(t('shared.bank routing number')),
                  description: currentIndividual?.disbursement_wire_bank_routing_number,
                },
                {
                  term: capitalize(t('shared.account name')),
                  description: currentIndividual?.disbursement_wire_account_name,
                },
              ]"
              :skeleton="skeleton"
              item_class="!mb-5"
              theme="form"
              v-if="currentIndividual?.disbursement_method === DisbursementMethod.Wire"
            />
            <VDescriptionList
              :items="[
                {
                  term: capitalize(t('shared.check payable to')),
                  description: currentIndividual?.disbursement_check_receiver_name,
                },
              ]"
              :skeleton="skeleton"
              theme="form"
              v-if="currentIndividual?.disbursement_method === DisbursementMethod.Check"
            />
            <VDescriptionList
              :items="[
                {
                  term: capitalize(t('shared.other detail', 0)),
                  description: currentIndividual?.disbursement_other_details,
                },
              ]"
              :skeleton="skeleton"
              theme="form"
              v-if="currentIndividual?.disbursement_method === DisbursementMethod.Other"
            />
          </VSection>
        </div>
        <div class="mb-8 flex gap-8">
          <VSection
            label="Tax Information"
            label_class="!font-semibold text-lg mb-3"
            class="!mb-0 w-1/2 rounded border-[1px] border-gray-300 p-3"
          >
            <template #actions v-if="isAdmin">
              <RouterLink :to="{ name: 'investing.individual.edit', hash: '#financial-verification-information' }">
                <VButton size="xs" variant="v-white" class="mb-2">
                  {{ capitalize(t('shared.edit')) }}
                </VButton>
              </RouterLink>
            </template>
            <VDescriptionList
              :items="[
                {
                  term: t('shared.SSN / Tax ID'),
                  description: currentIndividual?.tax_id,
                },
                {
                  term: t('shared.FinCEN ID'),
                  description: currentIndividual?.fincen_id,
                },
              ]"
              :skeleton="skeleton"
              item_class="!mb-5"
              theme="form"
            />
          </VSection>
          <VSection
            label="Login Information"
            label_class="!font-semibold text-lg mb-3"
            class="!mb-0 w-1/2 rounded border-[1px] border-gray-300 p-3"
          >
            <template #actions v-if="isAdmin && currentIndividual && !currentIndividual.registration_token">
              <div class="mb-2 flex gap-3">
                <VButton size="xs" variant="v-white" :click="request_password_reset" v-if="isAdmin">
                  Reset Password
                </VButton>
                <a :href="`${rails_url()}/users/${currentIndividual.user.id}/edit`">
                  <VButton size="xs" variant="v-white"> Edit login </VButton>
                </a>
              </div>
            </template>
            <template v-if="currentIndividual?.registration_token && isAdmin">
              <div class="h-[1px] w-full bg-gray-300"></div>
              <div class="w-full space-y-5">
                <div class="border-[1px] bg-red-100 p-2 text-sm text-red-700 ring-red-200/75">
                  {{ currentIndividual.name }} does not have a user account on Venture360 and therefore does not have
                  access to this profile and investments.
                </div>
                <div
                  class="border-[1px] bg-[#3982AF]/10 p-2 text-sm text-[#3982AF] ring-[#3982AF]/20"
                  v-if="currentIndividual.registration_token.sent_at"
                >
                  {{ currentIndividual.name }} was sent a registration email on
                  {{ format(currentIndividual.registration_token.sent_at, 'date') }}
                  {{ format(currentIndividual.registration_token.sent_at, 'time') }}
                </div>
                <div class="text-sm">
                  The investor can
                  <a class="hyperlink" :href="`${rails_url()}/register/user`" target="_blank"> register here </a> with
                  this token:
                  <br />
                  {{ currentIndividual.registration_token.token }}
                </div>
                <VButton size="sm" variant="v-blue" class="!h-fit !w-fit" :click="sendRegistrationInvitation">
                  Send registration invitation
                </VButton>
              </div>
            </template>
            <VDescriptionList
              :items="[
                {
                  term: capitalize(t('shared.created at')),
                  description: formatDateFNS(
                    new Date(currentIndividual?.user?.created_at || new Date()),
                    `EEEE, MMMM d, yyyy`,
                  ),
                },
                {
                  term: capitalize(t('shared.last login at')),
                  description: currentIndividual?.last_login
                    ? formatDateFNS(new Date(currentIndividual.last_login), `EEEE, MMMM d, Y 'at' h:mma`)
                    : 'Never',
                },
              ]"
              :skeleton="skeleton"
              item_class="!mb-5"
              theme="form"
              v-else-if="!currentIndividual?.registration_token"
            />
            <VDescriptionList
              :items="[
                {
                  term: capitalize(t('shared.membership agreement')),
                  description: '',
                },
              ]"
              :skeleton="skeleton"
              item_class="!mb-5"
              theme="form"
              v-if="currentIndividual?.membership_agreement_data?.individual_agreement"
            >
              <template #description="{ item }">
                <!-- individual's agreement data -->
                <div class="ml-auto w-[80%] text-end">
                  ✓ {{ is_current_individual_viewing ? 'You' : currentIndividual.name }} accepted the
                  <a
                    :href="currentIndividual.membership_agreement_data.individual_agreement.file_url"
                    class="hyperlink"
                    target="_blank"
                  >
                    {{ any_new_membership_agreement ? 'previous' : 'current' }} agreement
                  </a>
                  on
                  {{ format(currentIndividual.membership_agreement_data.individual_agreement.date, 'date') }}
                  {{ format(currentIndividual.membership_agreement_data.individual_agreement.date, 'time') }}
                </div>
              </template>
            </VDescriptionList>
          </VSection>
        </div>
        <div class="flex gap-8">
          <VSection
            :label="capitalize(t('shared.beneficiary', 0))"
            label_class="!font-semibold text-lg mb-3"
            class="h-fit w-1/3 space-y-5 rounded border-[1px] border-gray-300 p-3"
          >
            <template #actions v-if="isAdmin">
              <RouterLink :to="{ name: 'investing.individual.beneficiaries' }">
                <VButton size="custom" variant="v-blue" class="mb-3 !rounded-full p-1.5">
                  <VIcon name="plus" class="h-3 w-3" />
                </VButton>
              </RouterLink>
            </template>
            <div
              class="flex justify-between rounded bg-[#f8f9f9] p-3"
              v-for="beneficiary in beneficiaryStore.beneficiaries"
            >
              <div class="font-semibold">{{ beneficiary.name }}</div>
              <div class="font-semibold text-[#3d8a9d]">{{ n(beneficiary.allocation_percentage, 'percent') }}</div>
            </div>
          </VSection>
          <VSection
            :label="capitalize(t('shared.contact', 0))"
            label_class="!font-semibold text-lg mb-3"
            class="h-fit w-1/3 space-y-5 rounded border-[1px] border-gray-300 p-3"
          >
            <template #actions v-if="isAdmin">
              <RouterLink :to="{ name: 'investing.individual.contacts' }">
                <VButton size="custom" variant="v-blue" class="mb-3 !rounded-full p-1.5">
                  <VIcon name="plus" class="h-3 w-3" />
                </VButton>
              </RouterLink>
            </template>
            <div class="flex justify-between rounded bg-[#f8f9f9] p-3" v-for="contact in contactStore.contacts">
              <div class="font-semibold">{{ contact.name }}</div>
            </div>
          </VSection>
          <VSection
            label="Investment Groups"
            label_class="!font-semibold text-lg mb-3"
            class="h-fit w-1/3 space-y-5 rounded border-[1px] border-gray-300 p-3"
          >
            <div class="flex items-center gap-3 rounded bg-[#f8f9f9] p-3" v-for="group in currentIndividual?.groups">
              <div class="font-semibold">{{ group.name }}</div>
            </div>
          </VSection>
        </div>
      </template>
    </TheContent>
  </TheLayout>
</template>
