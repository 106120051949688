import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

// TODO: move to shared types
enum EntityType {
  Fund = 'fund',
  GP = 'gp',
  SPV = 'spv',
}

// TODO: move to shared types
type Entity = {
  _cid: CID // currently, this doesn't exist on the server
  _key: string // used to create _cid until the server provides a composite id
  id: string
  address: any
  calls: {
    total_capital: Money
    capital: Money
    management_fees: Money
    other_fees: Money
    _cid: CID
  }[]
  cash_position: Money
  cash_position_array: { _cid: string; value: Money }[]
  currency: string
  date_established: string
  distributions: {
    net_distributed: Money
    capital: Money
    carried_interest_distributed: Money
    other_fee_distributed: Money
    _cid: CID
    hurdle: Money
    profit: Money
    remaining: Money
  }[]
  entity_type: EntityType
  is_flexnote_spv: boolean
  is_venture360_the_administrator: boolean
  managers: any
  name: string
  scheduled_calls: {
    total_capital: Money
    capital: Money
    management_fees: Money
    other_fees: Money
    _cid: CID
  }[]
  structure: any
  tax_id: string
  type: EntityType
  questionnaire: any
  investor_profiles: any[]
  investor_stats: any[]
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type EntityMap = Map<CID, Entity>

export const useEntityStore = defineStore('investing/entityStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<EntityMap>(new Map())
  const allEntities = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))
  const funds = computed(() => allEntities.value.filter((item) => item.entity_type === EntityType.Fund))
  const gps = computed(() => allEntities.value.filter((item) => item.entity_type === EntityType.GP))
  const spvs = computed(() => allEntities.value.filter((item) => item.entity_type === EntityType.SPV))

  const fetchEntities = async (type: string, cid: string = 'all') => {
    clearItems(items)

    const { data, error } = await useFetch(`${baseUrl.value}/${type}s?cid=${cid}`)
      .get()
      .json<{ data: Entity[]; has_more: boolean }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Entities failed to load')
      return
    }

    addItems(
      items,
      data.value.data.map((item) => ({ ...item, _key: item.entity_type })),
    )
  }

  const fetchEntitiesV3 = async (type: string, cid: string = 'all') => {
    clearItems(items)

    const { data, error } = await useFetch(`${baseUrl.value}/${type}s/v3?cid=${cid}`)
      .get()
      .json<{ data: Entity[]; has_more: boolean }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Entities failed to load')
      return
    }

    addItems(
      items,
      data.value.data.map((item) => ({ ...item, _key: item.entity_type })),
    )
  }

  const fetchEntity = async ({ type, id }: { type: string; id: string }) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${type}/${id}`).get().json<{ data: Entity }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      console.log('data', data.value)
      return
    }

    addItem(items, { ...data.value.data, _key: data.value.data.entity_type })
  }

  const fetchEntityInfo = async ({ type, id }: { type: string; id: string }) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${type}/${id}/info`).get().json<{ data: Entity }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      console.log('data', data.value)
      return
    }

    addItem(items, { ...data.value.data, _key: data.value.data.entity_type })
  }

  const reset = () => {
    clearItems(items)
  }

  return {
    allEntities,
    items,
    funds,
    gps,
    spvs,

    fetchEntities,
    fetchEntitiesV3,
    fetchEntity,
    fetchEntityInfo,
    reset,
  }
})
