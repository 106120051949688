<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { capitalize, isEmpty } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useCan } from '@/modules/auth/composables/use-can'
import { RouterLinkBack, VButton, VButtonInvisible, VIcon, VSection, VTable } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { unflattenKey } from '@/modules/shared/utils/v-table'
import { useEntityStore } from '../stores/entity-store'
import EntitiesNav from '../components/entities-nav.vue'
import { useAuthStore } from '../../auth/stores/auth-store'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type EntityType = 'fund' | 'spv' | 'gp'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const formatEntityType = (type: string) => {
  const entity_types = {
    fund: 'Fund',
    spv: 'SPV',
    gp: 'GP',
  }

  return entity_types[type]
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { can } = useCan()

const { t } = useExtendedI18n()
const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()

const route = useRoute()
const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)

const isAdmin = computed(() => authStore.is_site_or_group_admin)

const entity_type = route.params.entity_type.slice(0, -1) as EntityType

const entityStore = useEntityStore()

const entities = computed(() => entityStore[route.params.entity_type as string])

const skeleton = ref(true)

onMounted(async () => {
  // prettier-ignore
  await Promise.all([
    workspaceStore.fetchCurrent(),
    entityStore.fetchEntitiesV3(entity_type),
  ])

  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :to="{ name: 'dashboard' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>

    <VSection>
      <EntitiesNav v-if="entity_type !== 'spv'" />
      <div class="mb-3 flex items-center justify-end gap-3">
        <a v-if="can('admin', cid)" :href="`${rails_url()}/funds/new?entity=${entity_type}`">
          <VButton size="md" variant="v-blue">
            <div class="mr-1 flex items-center space-x-2">
              <div><VIcon name="plus" /></div>
              <div>Add {{ formatEntityType(entity_type) }}</div>
            </div>
          </VButton>
        </a>
      </div>
      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            is_visible: true,
            fixed: true,
          },
          {
            key: 'investor_count',
            name: capitalize(t('shared.investor', 0)),
            type: 'number',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'committed',
            name: capitalize(t('shared.committed')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            aggregate: 'sum',
          },
          {
            key: 'called',
            name: capitalize(t('shared.called')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            aggregate: 'sum',
          },
          {
            key: 'commitment_remaining',
            name: capitalize(t('shared.commitment remaining')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            aggregate: 'sum',
          },
          {
            key: 'flex_or_traditional',
            name: capitalize(t('shared.flex or traditional')),
            type: 'string',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'distributed',
            name: capitalize(t('shared.distributed')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            aggregate: 'sum',
          },
          {
            key: 'cash_position',
            name: capitalize(t('shared.cash position')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            aggregate: 'sum',
            is_accessible: workspaceStore.isDataAccessible('investments.cash_position', isAdmin),
          },
          {
            key: 'management_fee',
            name: capitalize(t('shared.management fee', 0)),
            type: 'currency',
            align: 'right',
            is_visible: false,
            aggregate: 'sum',
          },
          {
            key: 'other_fees',
            name: capitalize(t('shared.other fee', 0)),
            type: 'currency',
            align: 'right',
            is_visible: false,
            aggregate: 'sum',
          },
          {
            key: 'carried_interest_distributed',
            name: capitalize(t('shared.carried interest')),
            type: 'currency',
            align: 'right',
            is_visible: false,
            aggregate: 'sum',
          },
          {
            key: 'legal_name',
            name: capitalize(t('shared.legal name')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'date_established',
            name: capitalize(t('shared.date established')),
            type: 'date',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'group.name',
            name: capitalize(t('shared.group')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'group.subdomain',
            name: capitalize(t('shared.subdomain')),
            type: 'string',
            align: 'left',
            is_accessible: can('admin', cid),
            is_visible: false,
          },
          {
            key: 'type',
            name: capitalize(t('shared.legal structure')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'tax_id',
            name: capitalize(t('shared.EIN')),
            type: 'string',
            align: 'left',
            is_accessible: can('admin', cid),
            is_visible: true,
          },
          {
            key: 'document_status',
            name: capitalize(t('shared.document status')),
            type: 'string',
            align: 'left',
            is_accessible: can('admin', cid),
            is_visible: true,
          },
          {
            key: 'selected_subscriptions',
            csv_key: 'selected_services',
            name: capitalize(t('shared.subscription')),
            type: 'string',
            align: 'left',
            is_accessible: can('admin', cid),
            is_visible: true,
          },
          {
            key: 'managers',
            name: capitalize(t('shared.manager', 0)),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'address.line1',
            name: capitalize(t('shared.line 1')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.line2',
            name: capitalize(t('shared.line 2')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.city',
            name: capitalize(t('shared.city')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.state',
            name: capitalize(t('shared.state')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.zip',
            name: capitalize(t('shared.postal code')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.country',
            name: capitalize(t('shared.country')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
        ]"
        :items="entities"
        :name="`${route.params.slug}-${route.params.entity_type}`"
        :skeleton="skeleton"
        :slots="['name', 'managers', 'selected_subscriptions']"
      >
        <template #name="{ item }">
          <div>
            <div class="mb-1 flex w-[400px] items-center justify-between">
              <div>
                <RouterLink
                  class="hyperlink"
                  :to="{
                    name: 'investing.entity-overview',
                    params: { entity_id: item.id, entity_type: item.entity_type },
                  }"
                >
                  {{ item.name }}
                </RouterLink>
              </div>
            </div>
            <div class="flex flex-wrap items-center justify-start space-x-2">
              <div class="whitespace-normal text-sm text-gray-600" v-if="!isEmpty(item.legal_name)">
                {{ item.legal_name }}
              </div>
            </div>
          </div>
        </template>
        <template #managers="{ item }">
          <span v-for="(manager, index) in unflattenKey(item, 'managers')" :key="manager.id">
            <RouterLink
              v-if="can('admin', cid)"
              class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
              :to="{ name: 'investing.individual-overview', params: { individual_id: manager.id } }"
            >
              {{ manager.name }}
            </RouterLink>
            <span class="text-gray-900" v-else>{{ manager.name }}</span>
            <span v-if="index !== unflattenKey(item, 'managers').length - 1">, </span>
          </span>
        </template>
        <template #selected_subscriptions="{ item }">
          <div class="flex flex-col px-3 text-left text-sm text-gray-600">
            <a
              v-for="subscription in unflattenKey(item, 'selected_subscriptions')"
              class="mb-1 inline-block w-fit whitespace-nowrap rounded bg-white px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-gray-300"
              :href="`/${item['group.subdomain']}/billing/${item.entity_type}/${item.id}`"
            >
              <span class="pr-1.5">{{ subscription.name }}</span>
              <span class="border-l border-gray-300 pl-1.5">{{ subscription.status }}</span>
            </a>
          </div>
        </template>
      </VTable>
    </VSection>
  </TheLayout>
</template>
