import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useFetch } from '@/modules/core/stores/fetch'
import { stringifyKey, type Key } from '@/modules/core/stores/kv'
import { initialMoney, sumMoney, type Money, toNumber } from '@/modules/shared/utils/money'
import { roi } from '@/modules/shared/utils/reporting'
import { Money } from '../../shared/utils/money'

///////////////////////////////////////////////////////////////////////////////

export enum InvestorType {
  Individual = 'individual',
  Fund = 'fund',
  GP = 'gp',
  SPV = 'spv',
}

export enum EntityType {
  Fund = 'fund',
  GP = 'gp',
  SPV = 'spv',
}

///////////////////////////////////////////////////////////////////////////////

export interface Entity {
  id: string
  type: EntityType
  address: Address
  capital_call_frequency: string
  carried_interest_percentage: number
  cid: string
  currency: string
  date_established: string
  entity_type: 'spv' | 'gp' | 'fund'
  fund_gp_name: string
  has_interest_catch_up_provision: boolean
  investment_period: string
  waterfall_calculation: string
  is_venture360_the_administrator: boolean
  legal_name: string
  management_fee_percentage: number
  name: string
  preferred_return_percentage: number
  tax_id: string
}

export interface Admin {
  id: string
  individual_id: string
  name: string
}

export interface Address {
  city: string
  country: string
  line1: string
  line2: string
  phone: string
  postal_code: string
  state: string
}

export interface Call {
  id: string
  batch_id: string
  commitment_id: string
  investor_id: string
  investor_type: InvestorType
  invoice_id: string
  due_date: string
  called: Money
  capital: Money
  invoice: Invoice
  is_outstanding: boolean
  is_settled: boolean
  management_fee: Money
  other_fee: Money
  status: string
  type: string
}

export interface CallExpanded extends Call {
  commitment: Commitment
  investor: Investor
}

export interface Commitment {
  id: string
  investor_id: string
  investor_type: InvestorType
  called: Money
  capital_called: Money
  carried_interest_percentage: number
  cash_position: Money
  committed: Money
  commitment_remaining: Money
  date: string
  fees_called: Money
  hurdle_remaining: Money
  management_fee_called: Money
  management_fee_percentage: number
  net_distributed: Money
  other_fee_called: Money
  ownership: number
  ownership_by_commitment: number
  ownership_computed: number
  ownership_override: number
  preferred_return_percentage: number
}

export interface CommitmentExpanded extends Commitment {
  investor: Investor
}

export interface Company {
  id: string
  industry_id: string
  initials: string
  logo_url: string
  name: string
}

export interface CompanyExpanded extends Company {
  accrued_interest: Money
  current_price: Money
  current_shares: number
  current_value: Money
  distributed: Money
  initial_price: Money
  initial_shares: number
  initial_value: Money
  roi: number
}

export interface Distribution {
  id: string
  investor_id: string
  investor_type: InvestorType
  carried_interest: Money
  company_id: string
  date: string
  gross: Money // aka distributed
  interest: Money
  net: Money
  other_fee: Money
  preferred_return: Money
  profit: Money
  return_of_capital: Money
}

export interface Industry {
  id: string
  name: string
}

export interface IndustryExpanded extends Industry {
  current_value: Money
  initial_value: Money
}

export interface Invoice {
  id: string
  display_status: string
  paid_at: string
}

export interface Investment {
  id: string
  investor_id: string
  investor_type: InvestorType
  legacy_investor_type: string // as the name implies, this is a legacy field
  accrued_interest: Money
  company_id: string
  current_price: Money
  current_shares: number
  current_value: Money
  date: string
  distributed: Money
  industry_id: string
  initial_price: Money
  initial_shares: number
  initial_value: Money
  investment_type: string
  method: string
  term_sheet_id: string
}

export interface Investor {
  type: InvestorType
  id: string
  cid: string
  address: Address
  email: string
  name: string
  investing_name: string
  tax_id: string
  callIds: Key[]
  commitmentIds: Key[]
}

export interface Note {
  id: string
  entity_id: string
  entity_type: EntityType
  text: string
}

export interface TermSheet {
  id: string
  company_id: string
  investment_type: string
  name: string
  terms: any
}

export interface TermSheetExpanded extends TermSheet {
  accrued_interest: Money
  company: Company
  current_price: Money
  current_shares: number
  current_value: Money
  distributed: Money
  industry: Industry
  initial_price: Money
  initial_shares: number
  initial_value: Money
}

export interface EntityExpanded extends Entity {
  admins: Admin[]
  calls: Call[]
  commitments: Commitment[]
  companies: Company[]
  distributions: Distribution[]
  industries: Industry[]
  investments: Investment[]
  investors: Investor[]
  invoices: Invoice[]
  notes: Note[]
  term_sheets: TermSheet[]
}

export const useEntityStore = defineStore('entityStore', () => {
  const entity = ref<EntityExpanded>(null)

  /////////////////////////////////////////////////////////////////////////////
  // Actions
  /////////////////////////////////////////////////////////////////////////////

  const fetch = async (id: string, { slug }: { slug: string }) => {
    const data = await useFetch(`/${slug}/investing/b/entities/${id}`).get<EntityExpanded>()
    entity.value = data
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  const createGet = <T>(key: string) => {
    return (id: string): T => {
      return entity.value?.[key]?.find((item: T) => item.id === id)
    }
  }
  const createList = <T>(key: string): T[] => entity.value?.[key] ?? []
  const createComputedList = <T>(key: string) => computed<T[]>(() => createList<T>(key))
  const createListBy = <T>(property: string) => {
    return (investorKeys: string[]): T[] => {
      const investorKeySet = new Set(investorKeys)

      return (
        entity.value?.[property]?.filter((record: { investor_id: string; investor_type: string }) => {
          const currentKey = stringifyKey([record.investor_id, record.investor_type])
          return investorKeySet.has(currentKey)
        }) ?? []
      )
    }
  }

  // Selected investors

  const selectedInvestorKeys = ref<string[]>([])

  watch(entity, (value) => {
    if (value) {
      selectAllInvestorKeys()
    }
  })

  const createKeyForInvestor = (investor: Investor) => {
    return stringifyKey([investor.id, investor.type])
  }

  const selectAllInvestorKeys = () => {
    selectedInvestorKeys.value = entity.value?.investors.map((i) => createKeyForInvestor(i)) ?? []
  }

  ///////////////////////////////////////////////////////////////////////////////
  // Admins
  ///////////////////////////////////////////////////////////////////////////////

  const getAdmin = createGet<Admin>('admins')
  const listAdmins = createComputedList<Admin>('admins')

  ///////////////////////////////////////////////////////////////////////////////
  // Calls
  ///////////////////////////////////////////////////////////////////////////////

  const getCall = createGet<Call>('calls')
  const listCalls = createComputedList<Call>('calls')
  const listCallsForInvestors = createListBy<Call>('calls')
  const listCallsForSelectedInvestors = computed(() => listCallsForInvestors(selectedInvestorKeys.value))

  ///////////////////////////////////////////////////////////////////////////////
  // Commitments
  ///////////////////////////////////////////////////////////////////////////////

  const getCommitment = createGet<Commitment>('commitments')
  const listCommitments = createComputedList<Commitment>('commitments')
  const listCommitmentsForInvestors = createListBy<Commitment>('commitments')
  const listCommitmentsForSelectedInvestors = computed(() => listCommitmentsForInvestors(selectedInvestorKeys.value))

  const getCommitmentTotalsForSelectedInvestors = computed(() => {
    const money = {
      ...initialMoney,
      currency: entity.value?.currency || 'USD',
    }

    return listCommitmentsForSelectedInvestors.value.reduce(
      (acc, commitment) => {
        acc.cash_position = sumMoney([acc.cash_position, commitment.cash_position])
        acc.committed = sumMoney([acc.committed, commitment.committed])
        acc.commitment_remaining = sumMoney([acc.commitment_remaining, commitment.commitment_remaining])
        acc.hurdle_remaining = sumMoney([acc.hurdle_remaining, commitment.hurdle_remaining])
        return acc
      },
      {
        cash_position: { ...money },
        committed: { ...money },
        commitment_remaining: { ...money },
        hurdle_remaining: { ...money },
      },
    )
  })

  ///////////////////////////////////////////////////////////////////////////////
  // Companies
  ///////////////////////////////////////////////////////////////////////////////

  const getCompany = createGet<Company>('companies')
  const listCompanies = createComputedList<Company>('companies')

  ///////////////////////////////////////////////////////////////////////////////
  // Distributions
  ///////////////////////////////////////////////////////////////////////////////

  const getDistribution = createGet<Distribution>('distributions')
  const listDistributions = createComputedList<Distribution>('distributions')
  const listDistributionsForInvestors = createListBy<Distribution>('distributions')
  const listDistributionsForSelectedInvestors = computed(() =>
    listDistributionsForInvestors(selectedInvestorKeys.value),
  )

  const getDistributionTotalsForSelectedInvestors = computed(() => {
    const money = {
      ...initialMoney,
      currency: entity.value?.currency || 'USD',
    }

    return listDistributionsForSelectedInvestors.value.reduce(
      (acc, distribution) => {
        acc.carried_interest = sumMoney([acc.carried_interest, distribution.carried_interest])
        acc.interest = sumMoney([acc.interest, distribution.interest])
        acc.net = sumMoney([acc.net, distribution.net])
        acc.other_fee = sumMoney([acc.other_fee, distribution.other_fee])
        acc.preferred_return = sumMoney([acc.preferred_return, distribution.preferred_return])
        acc.profit = sumMoney([acc.profit, distribution.profit])
        acc.return_of_capital = sumMoney([acc.return_of_capital, distribution.return_of_capital])

        return acc
      },
      {
        carried_interest: { ...money },
        interest: { ...money },
        net: { ...money },
        other_fee: { ...money },
        preferred_return: { ...money },
        profit: { ...money },
        return_of_capital: { ...money },
      },
    )
  })

  ///////////////////////////////////////////////////////////////////////////////
  // Industries
  ///////////////////////////////////////////////////////////////////////////////

  const getIndustry = createGet<Industry>('industries')
  const listIndustries = createComputedList<Industry>('industries')

  ///////////////////////////////////////////////////////////////////////////////
  // Investments
  ///////////////////////////////////////////////////////////////////////////////

  const getInvestment = createGet<Investment>('investments')
  const listInvestments = createComputedList<Investment>('investments')
  const listInvestmentsForInvestors = createListBy<Investment>('investments')
  const listInvestmentsForSelectedInvestors = computed(() => listInvestmentsForInvestors(selectedInvestorKeys.value))
  const listExpandedInvestmentsForSelectedInvestors = computed(() =>
    listInvestmentsForSelectedInvestors.value.map((investment) => {
      return {
        ...investment,
        company: getCompany(investment.company_id),
        industry: getIndustry(investment.industry_id),
        investor: getInvestor(investment.investor_id, investment.investor_type),
        roi: roi({
          current_value: toNumber(investment.current_value),
          distributed: toNumber(investment.distributed),
          initial_value: toNumber(investment.initial_value),
        }),
        term_sheet: getTermSheet(investment.term_sheet_id),
      }
    }),
  )

  const getInvestmentTotalsForSelectedInvestors = computed(() => {
    const money = {
      ...initialMoney,
      currency: entity.value?.currency || 'USD',
    }

    return listInvestmentsForSelectedInvestors.value.reduce(
      (acc, investment) => {
        acc.currentValue = sumMoney([acc.currentValue, investment.current_value])
        acc.initialValue = sumMoney([acc.initialValue, investment.initial_value])
        return acc
      },
      {
        currentValue: { ...money },
        initialValue: { ...money },
      },
    )
  })

  ///////////////////////////////////////////////////////////////////////////////
  // Investors
  ///////////////////////////////////////////////////////////////////////////////

  const getInvestor = (id: string, type: InvestorType): Investor => {
    return listInvestors.value?.find((item) => item.id === id && item.type === type)
  }

  const listInvestors = createComputedList<Investor>('investors')
  const listInvestorsForInvestors = createListBy<Investor>('investors')
  const listSelectedInvestors = computed(() => listInvestorsForInvestors(selectedInvestorKeys.value))

  ///////////////////////////////////////////////////////////////////////////////
  // Invoices
  ///////////////////////////////////////////////////////////////////////////////

  const getInvoice = createGet<Invoice>('invoices')
  const listInvoices = createComputedList<Invoice>('invoices')

  ///////////////////////////////////////////////////////////////////////////////
  // Notes
  ///////////////////////////////////////////////////////////////////////////////

  const getNote = createGet<Note>('notes')
  const listNotes = createComputedList<Note>('notes')

  ///////////////////////////////////////////////////////////////////////////////
  // TermSheets
  ///////////////////////////////////////////////////////////////////////////////

  const getTermSheet = createGet<TermSheet>('term_sheets')
  const listTermSheets = createComputedList<TermSheet>('term_sheets')

  ///////////////////////////////////////////////////////////////////////////////

  return {
    // State
    entity,

    // Actions
    fetch,

    // Getters

    getAdmin,
    listAdmins,

    getCall,
    listCalls,
    listCallsForInvestors,
    listCallsForSelectedInvestors,

    getCommitment,
    getCommitmentTotalsForSelectedInvestors,
    listCommitments,
    listCommitmentsForInvestors,
    listCommitmentsForSelectedInvestors,

    getCompany,
    listCompanies,

    getIndustry,
    listIndustries,

    getDistribution,
    getDistributionTotalsForSelectedInvestors,
    listDistributions,
    listDistributionsForInvestors,
    listDistributionsForSelectedInvestors,

    getInvestment,
    getInvestmentTotalsForSelectedInvestors,
    listExpandedInvestmentsForSelectedInvestors,
    listInvestments,
    listInvestmentsForInvestors,
    listInvestmentsForSelectedInvestors,

    getInvestor,
    listInvestors,

    getInvoice,
    listInvoices,

    getNote,
    listNotes,

    getTermSheet,
    listTermSheets,

    selectedInvestorKeys,
    createKeyForInvestor,
    selectAllInvestorKeys,
  }
})
