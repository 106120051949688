<script setup lang="ts">
import { capitalize } from 'lodash'
import { computed } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { VBarChart } from '@/modules/shared/components'
import { divide } from '@/modules/shared/utils/math'
import { Money, toNumber } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const toNumberOrZero = (money: Money) => {
  if (!money) return 0.0
  return toNumber(money)
}

///////////////////////////////////////////////////////////////////////////////

const props = defineProps<{
  commitmentTotals?: {
    committed: Money
    commitment_remaining: Money
  }
  callTotals?: {
    outstanding: {
      called: Money
      capital: Money
      management_fee: Money
      other_fee: Money
    }
    settled: {
      called: Money
      capital: Money
      management_fee: Money
      other_fee: Money
    }
  }
  committed?: Money
  committed_remaining?: Money
  called_received?: Money
  called_received_capital?: Money
  called_received_management_fees?: Money
  called_received_other_fees?: Money
  called_pending?: Money
  called_pending_capital?: Money
  called_pending_management_fees?: Money
  called_pending_other_fees?: Money
  skeleton?: boolean
}>()

const { n, t } = useExtendedI18n()

const ratio = (numerator: Money, denominator: Money) => {
  if (!numerator || !denominator) return 0.0
  if (toNumber(denominator) === 0.0) return 0.0
  return divide(toNumber(numerator), toNumber(denominator))
}

const denominator = computed<Money>(() => {
  const sortedValues = [
    {
      value: props.commitmentTotals?.committed ?? props.committed,
      number: toNumberOrZero(props.commitmentTotals?.committed ?? props.committed),
    },
    {
      value: props.callTotals?.settled.called ?? props.called_received,
      number: toNumberOrZero(props.callTotals?.settled.called ?? props.called_received),
    },
    {
      value: props.callTotals?.outstanding.called ?? props.called_pending,
      number: toNumberOrZero(props.callTotals?.outstanding.called ?? props.called_pending),
    },
  ].sort((a, b) => b.number - a.number)

  return sortedValues[0].value
})
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white px-0 py-8 dark:border-[#161618] dark:bg-[#1B1B1F]">
    <VBarChart
      :segments="[
        {
          label: capitalize(t('shared.committed')),
          value: n(props.commitmentTotals?.committed ?? committed, 'currency'),
          style: 'bg-[#7ABCBF]',
          ratio: ratio(props.commitmentTotals?.committed ?? committed, denominator),
        },
        {
          label: capitalize(t('shared.settled')),
          value: n(props.callTotals?.settled.called ?? called_received, 'currency'),
          style: 'bg-[#5C8DB4]',
          ratio: ratio(props.callTotals?.settled.called ?? called_received, denominator),
          segments: [
            {
              label: capitalize(t('shared.capital')),
              value: n(props.callTotals?.settled.capital ?? called_received_capital, 'currency'),
              style: 'bg-[#5C8DB4]/50',
              ratio: ratio(
                props.callTotals?.settled.capital ?? called_received_capital,
                props.callTotals?.settled.called ?? called_received,
              ),
            },
            {
              label: capitalize(t('shared.management fee', 0)),
              value: n(props.callTotals?.settled.management_fee ?? called_received_management_fees, 'currency'),
              style: 'bg-[#5cb493]',
              ratio: ratio(
                props.callTotals?.settled.management_fee ?? called_received_management_fees,
                props.callTotals?.settled.called ?? called_received,
              ),
            },
            {
              label: capitalize(t('shared.other fee', 0)),
              value: n(props.callTotals?.settled.other_fee ?? called_received_other_fees, 'currency'),
              style: 'bg-[#5cb493]',
              ratio: ratio(
                props.callTotals?.settled.other_fee ?? called_received_other_fees,
                props.callTotals?.settled.called ?? called_received,
              ),
            },
          ],
        },
        {
          label: capitalize(t('shared.outstanding')),
          value: n(props.callTotals?.outstanding.called ?? called_pending, 'currency'),
          style: 'bg-[#7b5bb3]',
          ratio: ratio(props.callTotals?.outstanding.called ?? called_pending, denominator),
          segments: [
            {
              label: capitalize(t('shared.capital')),
              value: n(props.callTotals?.outstanding.capital ?? called_pending_capital, 'currency'),
              style: 'bg-[#7b5bb3]/75',
              ratio: ratio(
                props.callTotals?.outstanding.capital ?? called_pending_capital,
                props.callTotals?.outstanding.called ?? called_pending,
              ),
            },
            {
              label: capitalize(t('shared.management fee', 0)),
              value: n(props.callTotals?.outstanding.management_fee ?? called_pending_management_fees, 'currency'),
              style: 'bg-[#5cb493]',
              ratio: ratio(
                props.callTotals?.outstanding.management_fee ?? called_pending_management_fees,
                props.callTotals?.outstanding.called ?? called_pending,
              ),
            },
            {
              label: capitalize(t('shared.other fee', 0)),
              value: n(props.callTotals?.outstanding.other_fee ?? called_pending_other_fees, 'currency'),
              style: 'bg-[#5cb493]',
              ratio: ratio(
                props.callTotals?.outstanding.other_fee ?? called_pending_other_fees,
                props.callTotals?.outstanding.called ?? called_pending,
              ),
            },
          ],
        },
        {
          label: capitalize(t('shared.remaining')),
          value: n(props.commitmentTotals?.commitment_remaining ?? committed_remaining, 'currency'),
          style: 'bg-gray-300',
          ratio: ratio(props.commitmentTotals?.commitment_remaining ?? committed_remaining, denominator),
        },
      ]"
      :skeleton="skeleton"
    />
  </div>
</template>
