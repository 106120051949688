import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money, initialMoney } from '@/modules/shared/utils/money'
import { addItems, clearItems } from '@/modules/shared/utils/store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Commitment = {
  id: ID
  investor: {
    _cid: CID
    id: ID
    name: string
  }
}

export type OwnershipTransfer = {
  id: ID
  buyer_commitment: Commitment
  date: string
  distribution: {
    carried_interest: Money
    gross: Money
    net: Money
    other_fee: Money
  }
  price_per_unit: Money
  seller_commitment: Commitment
  units: number
}

export const ownership_transfer_form_initial_state = {
  buyer_investor_cid: null,
  carried_interest_amount: null,
  date: null,
  other_fee: null,
  price_per_unit: null,
  seller_investor_cid: null,
  units_sold: null,
}

export const transfer_commitment_initial_state = {
  capital_committed: initialMoney,
  capital_called: initialMoney,
  management_fees: initialMoney,
  other_fees: initialMoney,
  total_called: initialMoney,
  commitment_remaining: initialMoney,
  investor: {
    id: null,
    _cid: null,
    name: null,
    ruby_type: null,
  },
  after_transfer: {
    capital_committed: initialMoney,
    capital_called: initialMoney,
    management_fees: initialMoney,
    other_fees: initialMoney,
    total_called: initialMoney,
    commitment_remaining: initialMoney,
  },
}

export const ownership_transfer_initial_state = {
  id: null,
  buyer_commitment: { ...transfer_commitment_initial_state },
  commitment_purchased: initialMoney,
  commitment_purchased_percent: 0,
  date: null,
  disbursement: {
    capital: initialMoney,
    carried_interest: initialMoney,
    other_fees: initialMoney,
    total_disbursed: initialMoney,
  },
  entity: {
    id: null,
    entity_type: null,
    name: null,
  },
  seller_commitment: { ...transfer_commitment_initial_state },
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type OwnershipTransferMap = Map<CID, OwnershipTransfer>

export const useOwnershipTransferStore = defineStore('investing/ownershipTransferStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<OwnershipTransferMap>(new Map())
  const ownership_transfers = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchOwnershipTransfers = async (investable_type, investable_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/ownership-transfers`)
      .get()
      .json<{ data: OwnershipTransfer[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Fetching Transfers Failed')
      return
    }

    clearItems(items)
    addItems(
      items,
      data.value.data.map((call) => ({ ...call, _cid: call.id })),
    )
  }

  const transferOwnership = async (tranferOwnership: any, investable_type, investable_id) => {
    const { error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/ownership-transfer/add`)
      .post(tranferOwnership)
      .json()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Transfer Failed')
      return
    }
  }

  const removeOwnershipTransfer = async (ownership_transfer_id: ID, investable_type, investable_id) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}/${investable_type}/${investable_id}/ownership-transfer/${ownership_transfer_id}/remove`,
    )
      .delete()
      .json()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Removing Transfer Failed')
      return
    }

    await fetchOwnershipTransfers(investable_type, investable_id)
  }

  return {
    items,
    ownership_transfers,

    fetchOwnershipTransfers,
    removeOwnershipTransfer,
    transferOwnership,
  }
})
