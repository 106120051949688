<script setup lang="ts">
import { capitalize } from 'lodash'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCan } from '@/modules/auth/composables/use-can'
import { rails_url } from '@/modules/shared/utils/rails'
import { VTabList } from '@/modules/shared/components'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

defineProps<{
  selectedTab:
    | 'calls'
    | 'capital-account'
    | 'distributions'
    | 'esignatures'
    | 'files'
    | 'investors'
    | 'notes'
    | 'overview'
    | 'portfolio'
    | 'transfers'
}>()

const { can } = useCan()
const { t } = useI18n()
const route = useRoute()
const authStore = useAuthStore()

const id = computed(() => route.params.entityId)
const type = computed(() => 'entities')
const cid = computed(() => `${type.value}:${id.value}`)
const entityURL = computed(() => `${rails_url()}/funds/${id.value}`)
</script>

<template>
  <div class="mb-12 px-4 sm:px-0">
    <VTabList
      :tabs="[
        {
          current: selectedTab === 'overview',
          name: capitalize(t('shared.overview')),
          to: {
            name: 'entities.overview',
            params: {
              entityId: id,
            },
          },
        },
        {
          current: selectedTab === 'portfolio',
          name: capitalize(t('shared.portfolio')),
          to: {
            name: 'entities.portfolio',
            params: {
              entityId: id,
            },
          },
        },
        {
          current: selectedTab === 'investors',
          name: capitalize(t('shared.investor', 0)),
          to: {
            name: 'entities.investors',
            params: {
              entityId: id,
            },
          },
        },
        {
          current: selectedTab === 'calls',
          name: capitalize(t('shared.call', 0)),
          to: {
            name: 'entities.calls',
            params: {
              entityId: id,
            },
          },
        },
        {
          current: selectedTab === 'distributions',
          name: capitalize(t('shared.distribution', 0)),
          to: {
            name: 'entities.distributions',
            params: {
              entityId: id,
            },
          },
        },
        {
          current: selectedTab === 'transfers',
          name: capitalize(t('shared.transfer', 0)),
          to: {
            name: 'investing.transfers',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
        },
        {
          current: selectedTab === 'capital-account',
          name: capitalize(t('shared.capital account')),
          to: {
            name: 'investing.entity.capital-account',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
          visible: authStore.is_site_admin,
        },
        {
          current: selectedTab === 'esignatures',
          name: t('shared.eSignature', 0),
          to: {
            name: 'investing.entity.documents',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
        },
        {
          current: selectedTab === 'files',
          name: capitalize(t('shared.file', 0)),
          to: `${entityURL}/files`,
        },
        {
          current: selectedTab === 'notes',
          name: 'Notes',
          to: `${entityURL}/notes`,
          visible: can('siteadmin', cid),
        },
      ]"
    />
  </div>
</template>
