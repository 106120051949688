<script setup lang="ts">
import { capitalize } from 'lodash'
import { computed } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { VBarChart } from '@/modules/shared/components'
import { divide } from '@/modules/shared/utils/math'
import { Money, toNumber, sum } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////

const props = defineProps<{
  commitmentTotals?: {
    hurdle_remaining: Money
  }
  distributionTotals?: {
    carried_interest: Money
    interest: Money
    net: Money
    other_fee: Money
    preferred_return: Money
    profit: Money
    return_of_capital: Money
  }
  currency?: string
  hurdle?: Money
  hidden_bars?: string[]
  distribution_distributed?: Money
  distribution_distributed_capital?: Money
  distribution_distributed_profit?: Money
  distribution_preferred_return?: Money
  distribution_interest_earned?: Money
  distribution_distributed_carried_interest?: Money
  distribution_distributed_other_fees?: Money
  distribution_pending?: Money
  distribution_pending_capital?: Money
  distribution_pending_profit?: Money
  distribution_pending_carried_interest?: Money
  distribution_pending_other_fees?: Money
  distribution_remaining?: Money
  skeleton?: boolean
}>()

const { n, t } = useExtendedI18n()

const is_section_hidden = (section) => {
  if (!props.hidden_bars) return false
  return props.hidden_bars.includes(section)
}

const net = computed(() => props.distribution_distributed || props.distributionTotals?.net)
const profit = computed(() => props.distribution_distributed_profit || props.distributionTotals?.profit)
const return_of_capital = computed(
  () => props.distribution_distributed_capital || props.distributionTotals?.return_of_capital,
)
const hurdle_remaining = computed(() => props.distribution_remaining || props.commitmentTotals?.hurdle_remaining)
const carried_interest = computed(
  () => props.distribution_distributed_carried_interest || props.distributionTotals?.carried_interest,
)
const other_fees = computed(() => props.distribution_distributed_other_fees || props.distributionTotals?.other_fee)
const preferred_return = computed(
  () => props.distribution_preferred_return || props.distributionTotals?.preferred_return,
)
const interest = computed(() => props.distribution_interest_earned || props.distributionTotals?.interest)

const denominator = computed<Money>(() => {
  const sorted_values = [
    {
      value: net.value,
      number: toNumberOrZero(net.value),
      is_hidden: is_section_hidden('distributed'),
    },
    {
      value: profit.value,
      number: toNumberOrZero(profit.value),
      is_hidden: is_section_hidden('distributed.profit'),
    },
    {
      value: return_of_capital.value,
      number: toNumberOrZero(return_of_capital.value),
      is_hidden: is_section_hidden('distributed.return_of_capital'),
    },
    {
      value: hurdle_remaining.value,
      number: toNumberOrZero(hurdle_remaining.value),
      is_hidden: is_section_hidden('distributed.hurdle_remaining'),
    },
    {
      value: distributed_total_fees.value,
      number: toNumberOrZero(distributed_total_fees.value),
      is_hidden: is_section_hidden('total_fees'),
    },
    {
      value: carried_interest.value,
      number: toNumberOrZero(carried_interest.value),
      is_hidden: is_section_hidden('total_fees.carried_interest'),
    },
    {
      value: other_fees.value,
      number: toNumberOrZero(other_fees.value),
      is_hidden: is_section_hidden('total_fees.other_fees'),
    },
  ]
    .filter((data) => !data.is_hidden)
    .sort((a, b) => b.number - a.number)

  return sorted_values[0].value
})

const toNumberOrZero = (money: Money) => {
  if (!money) return 0.0
  return toNumber(money)
}

const distributed_total_fees = computed(() => sum([carried_interest.value, other_fees.value]))

const ratio = (numerator: Money, denominator: Money, key?) => {
  if (toNumberOrZero(denominator) === 0.0) return 0.0
  return divide(toNumberOrZero(numerator), toNumberOrZero(denominator))
}
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white px-0 py-8 dark:border-[#161618] dark:bg-[#1B1B1F]">
    <VBarChart
      :segments="[
        {
          label: capitalize(t('shared.net distributed')),
          value: n(net, 'currency'),
          style: 'bg-[#36326B]',
          ratio: ratio(net, denominator),
          is_hidden: is_section_hidden('distributed'),
          segments: [
            {
              label: capitalize(t('shared.profit')),
              value: n(profit, 'currency'),
              style: 'bg-[#36326B]/75',
              ratio: ratio(profit, net),
              is_hidden: is_section_hidden('distributed.profit'),
            },
            {
              label: capitalize(t('shared.preferred return')),
              value: n(preferred_return, 'currency'),
              style: 'bg-[#36326B]/75',
              ratio: ratio(preferred_return, net),
              is_hidden: is_section_hidden('distributed.preferred_return'),
            },
            {
              label: capitalize(t('shared.interest')),
              value: n(interest, 'currency'),
              style: 'bg-[#36326B]/75',
              ratio: ratio(interest, net),
              is_hidden: is_section_hidden('distributed.interest'),
            },
            {
              label: capitalize(t('shared.return of capital')),
              value: n(return_of_capital, 'currency'),
              style: 'bg-[#36326B]/75',
              ratio: ratio(return_of_capital, net),
              is_hidden: is_section_hidden('distributed.return_of_capital'),
            },
            {
              label: capitalize(t('shared.hurdle remaining')),
              value: n(hurdle_remaining, 'currency'),
              style: 'bg-gray-300',
              ratio: ratio(hurdle_remaining, denominator, 'remaining'),
              is_hidden: is_section_hidden('distributed.hurdle_remaining'),
            },
          ],
        },
        {
          label: capitalize(t('shared.total fee', 0)),
          value: n(distributed_total_fees, 'currency'),
          style: 'bg-[#36326B]',
          ratio: ratio(distributed_total_fees, denominator),
          is_hidden: is_section_hidden('total_fees'),
          segments: [
            {
              label: capitalize(t('shared.carried interest', 0)),
              value: n(carried_interest, 'currency'),
              style: 'bg-[#8E6080]',
              ratio: ratio(carried_interest, net),
              is_hidden: is_section_hidden('total_fees.carried_interest'),
            },
            {
              label: capitalize(t('shared.other fee', 0)),
              value: n(other_fees, 'currency'),
              style: 'bg-[#8E6080]/75',
              ratio: ratio(other_fees, net),
              is_hidden: is_section_hidden('total_fees.other_fees'),
            },
          ],
        },
        // {
        //   label: capitalize(t('shared.pending')),
        //   value: n(distribution_pending, 'currency'),
        //   style: 'bg-[#408F93]/50',
        //   ratio: ratio(distribution_pending, denominator),
        //   segments: [
        //     {
        //       label: capitalize(t('shared.capital')),
        //       value: n(distribution_pending_capital, 'currency'),
        //       style: 'bg-[#408F93]/50',
        //       ratio: ratio(distribution_pending_capital, distribution_pending),
        //     },
        //     {
        //       label: capitalize(t('shared.profit')),
        //       value: n(distribution_pending_profit, 'currency'),
        //       style: 'bg-[#408F93]/50',
        //       ratio: ratio(distribution_pending_profit, distribution_pending),
        //     },
        //     {
        //       label: capitalize(t('shared.carried interest', 0)),
        //       value: n(distribution_pending_carried_interest, 'currency'),
        //       style: 'bg-gray-700/50',
        //       ratio: ratio(distribution_pending_carried_interest, distribution_pending),
        //     },
        //     {
        //       label: capitalize(t('shared.other fee', 0)),
        //       value: n(distribution_pending_other_fees, 'currency'),
        //       style: 'bg-gray-600/50',
        //       ratio: ratio(distribution_pending_other_fees, distribution_pending),
        //     },
        //   ],
        // },
      ]"
      :skeleton="skeleton"
    />
  </div>
</template>
