import { difference, sum, times, toMoney } from '@/modules/shared/utils/money'
import { ownership_transfer_initial_state } from '../stores/ownership-transfer-store'
import { get } from 'lodash'

export const getOwnershipTransferSummary = (
  buyer = null,
  carried_interest = 0,
  currency = 'USD',
  other_fees = 0,
  price_per_unit = 0,
  seller = null,
  units_sold = 0,
) => {
  const summary = { ...ownership_transfer_initial_state }
  const amount_paid = units_sold * price_per_unit
  const transferred_percent = units_sold / (seller?.units || 1)
  const remaining_percent = 1 - transferred_percent

  // COMPUTE DISBURSEMENT
  const disbursement_capital = toMoney(amount_paid || 0, currency)
  const disbursement_carried_interest = toMoney(carried_interest || 0, currency)
  const disbursement_other_fees = toMoney(other_fees || 0, currency)
  const disbursement_total = difference([disbursement_capital, disbursement_carried_interest, disbursement_other_fees])
  summary.disbursement = {
    capital: disbursement_capital,
    carried_interest: disbursement_carried_interest,
    other_fees: disbursement_other_fees,
    total_disbursed: disbursement_total,
  }

  // COMPUTE SELLER'S BEFORE AND AFTER TRANSFER SUMMARY
  if (seller) {
    summary.seller_commitment = {
      capital_called: get(seller, 'capital_called'),
      capital_committed: get(seller, 'capital_committed'),
      commitment_remaining: get(seller, 'commitment_remaining'),
      management_fees: get(seller, 'management_fees'),
      other_fees: get(seller, 'other_fees'),
      total_called: get(seller, 'total_called'),
      investor: get(seller, 'investor'),
      after_transfer: {
        capital_called: times(get(seller, 'capital_called'), remaining_percent),
        capital_committed: times(get(seller, 'capital_committed'), remaining_percent),
        commitment_remaining: times(get(seller, 'commitment_remaining'), remaining_percent),
        management_fees: times(get(seller, 'management_fees'), remaining_percent),
        other_fees: times(get(seller, 'other_fees'), remaining_percent),
        total_called: times(get(seller, 'total_called'), remaining_percent),
      },
    }
  }

  // COMPUTE BUYER'S BEFORE TRANSFER SUMMARY
  if (buyer) {
    summary.buyer_commitment = {
      capital_called: get(buyer, 'capital_called'),
      capital_committed: get(buyer, 'capital_committed'),
      commitment_remaining: get(buyer, 'commitment_remaining'),
      management_fees: get(buyer, 'management_fees'),
      other_fees: get(buyer, 'other_fees'),
      total_called: get(buyer, 'total_called'),
      investor: get(buyer, 'investor'),
    }
  }

  // COMPUTE BUYER'S AFTER TRANSFER SUMMARY
  const transferred_capital_called = times(get(summary, 'seller_commitment.capital_called'), transferred_percent)
  const transferred_commitment = times(get(summary, 'seller_commitment.capital_committed'), transferred_percent)
  const transferred_commitment_remaining = times(
    get(summary, 'seller_commitment.commitment_remaining'),
    transferred_percent,
  )
  const transferred_management_fees = times(get(summary, 'seller_commitment.management_fees'), transferred_percent)
  const transferred_other_fees = times(get(summary, 'seller_commitment.other_fees'), transferred_percent)
  const transferred_total_called = times(get(summary, 'seller_commitment.total_called'), transferred_percent)
  summary.buyer_commitment.after_transfer = {
    capital_called: sum([get(summary, 'buyer_commitment.capital_called'), transferred_capital_called]),
    capital_committed: sum([get(summary, 'buyer_commitment.capital_committed'), transferred_commitment]),
    commitment_remaining: sum([
      get(summary, 'buyer_commitment.commitment_remaining'),
      transferred_commitment_remaining,
    ]),
    management_fees: sum([get(summary, 'buyer_commitment.management_fees'), transferred_management_fees]),
    other_fees: sum([get(summary, 'buyer_commitment.other_fees'), transferred_other_fees]),
    total_called: sum([get(summary, 'buyer_commitment.total_called'), transferred_total_called]),
  }

  return summary
}
