<script setup lang="ts">
import { get } from 'lodash'
import { computed, onMounted } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { useFilter } from '@/modules/core/composables/useFilter'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import { useFetch } from '@/modules/core/stores/fetch'
import CallsBarChart from '@/modules/investing/components/calls-bar-chart.vue'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import { useEntityStore } from '@/modules/investing/stores/better-entity-store'
import {
  ActionsGroup,
  ActionsMenu,
  ActionItem,
  VButton,
  VButtonGroup,
  VIcon,
  VSection,
  VTable,
} from '@/modules/shared/components'
import { sumMoney, initialMoney } from '@/modules/shared/utils/money'
import { rails_url } from '@/modules/shared/utils/rails'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'

///////////////////////////////////////////////////////////////////////////////
// Calls
///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()
const entity = computed(() => entityStore.entity)

// Expansion allows us to add additional data to the call for the VTable component
// This is currently required in order to search and sort by expanded data
// NOTE run this before filtering to reduce the number of times we run this function
// NOTE expansion could be handled by the API, however, doing it client side simplifies the API
// QUESTION should we move this to the store?
const expandedCalls = computed(() => {
  return entityStore.listCallsForSelectedInvestors.map((call) => {
    return {
      ...call,
      commitment: entityStore.getCommitment(call.commitment_id),
      investor: entityStore.getInvestor(call.investor_id, call.investor_type),
      invoice: entityStore.getInvoice(call.invoice_id),
    }
  })
})

const { filters, setFilter } = useFilter({
  type: null, // 'null', 'sent', 'scheduled'
  minCalled: null,
})

const filteredCalls = computed(() => {
  return expandedCalls.value.filter((call) => {
    if (filters.value.type === 'sent' && call.status !== 'sent') {
      return false
    }

    if (filters.value.type === 'scheduled' && call.status !== 'unsent') {
      return false
    }

    if (filters.value.minCalled !== null && call.called.amount < filters.value.minCalled) {
      return false
    }

    return true
  })
})

const callTotals = computed(() => {
  const money = {
    ...initialMoney,
    currency: entityStore.entity?.currency || 'USD',
  }

  return filteredCalls.value.reduce(
    (acc, call) => {
      if (call.is_outstanding) {
        acc.outstanding.called = sumMoney([acc.outstanding.called, call.called])
        acc.outstanding.capital = sumMoney([acc.outstanding.capital, call.capital])
        acc.outstanding.management_fee = sumMoney([acc.outstanding.management_fee, call.management_fee])
        acc.outstanding.other_fee = sumMoney([acc.outstanding.other_fee, call.other_fee])
      }

      if (call.is_settled) {
        acc.settled.called = sumMoney([acc.settled.called, call.called])
        acc.settled.capital = sumMoney([acc.settled.capital, call.capital])
        acc.settled.management_fee = sumMoney([acc.settled.management_fee, call.management_fee])
        acc.settled.other_fee = sumMoney([acc.settled.other_fee, call.other_fee])
      }

      return acc
    },
    {
      outstanding: {
        called: { ...money },
        capital: { ...money },
        management_fee: { ...money },
        other_fee: { ...money },
      },
      settled: {
        called: { ...money },
        capital: { ...money },
        management_fee: { ...money },
        other_fee: { ...money },
      },
    },
  )
})

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entityId, slug } = useRoute().params as { entityId: string; slug: string }

const deleteCall = async (id: string) => {
  if (!window.confirm('Are you sure?')) return
  // await callStore.removeCall(item.id, props.investable_type, props.investable_id)
  await fetch()
}

const fetch = async () => {
  await entityStore.fetch(entityId, { slug })
}

const generateInvoiceForCall = async (callId: string) => {
  await useFetch(`/${slug}/investing/b/entities/${entityId}/calls/${callId}/generate-invoice`).post()
  await fetch()
}

const sendInvoiceReminder = async (callId: string) => {
  await useFetch(`/${slug}/investing/b/entities/${entityId}/calls/${callId}/send-invoice-reminder`).post()
  await fetch()
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const isAdmin = computed(() => true)

///////////////////////////////////////////////////////////////////////////////
// Rails Utils
///////////////////////////////////////////////////////////////////////////////

const createInvoiceUrl = (id: string) => {
  return `${rails_url()}/invoices/${id}`
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { skeleton, hideSkeleton } = useSkeleton()

onMounted(async () => {
  await fetch()
  hideSkeleton()
})
</script>

<template>
  <EntityLayout selectedTab="calls">
    <VSection class="flex items-center justify-between">
      <VButtonGroup>
        <VSelectInvestor v-model="entityStore.selectedInvestorKeys" :investors="entityStore.listInvestors" />
        <VButton :active="filters.type === null" class="w-32" @click="setFilter('type', null)">All</VButton>
        <VButton :active="filters.type === 'sent'" class="w-32" @click="setFilter('type', 'sent')">Sent</VButton>
        <VButton :active="filters.type === 'scheduled'" class="w-32" @click="setFilter('type', 'scheduled')"
          >Scheduled</VButton
        >
      </VButtonGroup>
      <RouterLink
        :to="{
          name: 'investing.capital-call.new',
          params: { entity_type: entity?.entity_type, entity_id: entity?.id },
        }"
        v-if="entity"
      >
        <VButton size="md" variant="v-blue">
          <div class="mr-1 flex items-center space-x-2">
            <div><VIcon name="plus" /></div>
            <div>Call capital</div>
          </div>
        </VButton>
      </RouterLink>
    </VSection>
    <div v-if="false">
      <pre>Example of a better filtering system we could implement</pre>
      <label>Min Called</label>
      <input type="number" v-model="filters.minCalled" />
    </div>
    <VSection>
      <CallsBarChart
        :commitmentTotals="entityStore.getCommitmentTotalsForSelectedInvestors"
        :callTotals="callTotals"
        :skeleton="skeleton"
      />
    </VSection>
    <VSection>
      <VTable
        :columns="[
          {
            key: 'investor.name',
            name: 'Investor',
            type: 'string',
            align: 'left',
            fixed: true,
            is_visible: true,
          },
          {
            key: 'due_date',
            name: 'Due Date',
            sorted: true,
            type: 'date',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'type',
            name: 'Type',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'capital',
            name: 'Capital',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'management_fee',
            name: 'Management Fee',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'other_fee',
            name: 'Other Fee',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'called',
            name: 'Total',
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'invoice.display_status',
            name: 'Invoice Status',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_visible: true,
          },
        ]"
        :items="filteredCalls"
        :name="`entities-${1}-calls`"
        :skeleton="skeleton"
      >
        <template #investor.name="{ item: call }">
          <RouterLink
            class="hyperlink"
            :to="
              get(call, 'investor.ruby_type') === 'fundingentity'
                ? { name: 'investing.other-entity.overview', params: { other_entity_id: get(call, 'investor.id') } }
                : { name: 'investing.individual-overview', params: { individual_id: get(call, 'investor.id') } }
            "
            >{{ get(call, 'investor.name') }}</RouterLink
          >
        </template>
        <template #invoice.display_status="{ item: call }">
          <a
            v-if="!call.invoice"
            class="hyperlink"
            :class="get(call, 'invoice.display_status') === 'Past Due' ? '!text-red-400 !decoration-red-400/50' : ''"
            :href="createInvoiceUrl(call.invoice_id)"
          >
            {{ get(call, 'invoice.display_status') }}
          </a>
          <VButton
            v-else-if="isAdmin && !call.is_investor_transfer"
            :click="() => generateInvoiceForCall(call.id)"
            size="xs"
            >Generate Invoice</VButton
          >
        </template>
        <template #actions="{ item: call }">
          <ActionsMenu>
            <ActionsGroup>
              <ActionItem
                tag="RouterLink"
                text="Edit call"
                :to="{
                  name: 'investing.capital-call.edit',
                  params: { capital_call_id: get(call, 'investor_set_transaction_id') },
                }"
              />
              <ActionItem
                v-if="call.invoice !== null && !get(call, 'invoice.paid_at')"
                @click="sendInvoiceReminder(call.invoice_id)"
                text="Send invoice reminder"
              />
            </ActionsGroup>
            <ActionsGroup>
              <ActionItem v-if="!call.is_investor_transfer" @click="deleteCall(call.id)" text="Delete call" />
            </ActionsGroup>
          </ActionsMenu>
        </template>
      </VTable>
    </VSection>
  </EntityLayout>
</template>
