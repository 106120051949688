import { ref } from 'vue'

export type Key = string[]

export const parseKey = (key: string): Key => JSON.parse(key)

export const stringifyKey = (key: Key) => JSON.stringify(key)

export const createKv = <T>() => {
  const map = ref(new Map())

  const get = (key: Key) => {
    const value = map.value.get(stringifyKey(key)) || null
    return { key, value }
  }

  const getMany = (keys: Key[]) => keys.map(get)

  const list = ({ prefix }: { prefix: Key }) => {
    return Array.from(map.value.keys())
      .map(parseKey)
      .filter((key) => {
        if (key.length < prefix.length) return false
        return prefix.every((p, i) => key[i] === p)
      })
      .map(get)
  }

  const has = (key: Key) => map.value.has(stringifyKey(key))

  const del = (key: Key) => map.value.delete(stringifyKey(key))

  const reset = () => (map.value = new Map())

  const set = (key: Key, entry: T) => map.value.set(stringifyKey(key), entry)

  return {
    map,

    // read
    get,
    getMany,
    has,
    list,

    // write
    del,
    reset,
    set,
  }
}
