import { Money, sum, toMoney, difference, initialMoney, minus, times } from '@/modules/shared/utils/money'

//////////////////////////////////////////////////////////////////////
//// Types
//////////////////////////////////////////////////////////////////////
type TransactionSummary = {
  called_capital: Money
  called_management_fees: Money
  called_other_fees: Money
  total_called: Money
  distributed_capital: Money
  distributed_return_of_capital: Money
  distributed_preferred_return: Money
  distributed_profit: Money
  distributed_carried_interest: Money
  distributed_other_fees: Money
  realized_gain: Money
  total_distributed: Money
  total_cash: Money
}

type BookValueQuarter = {
  beginning_balance: Money
  ending_balance: Money
  transaction_summary: TransactionSummary
}

type BookValueYear = {
  beginning_balance: Money
  ending_balance: Money
  Q1: BookValueQuarter
  Q2: BookValueQuarter
  Q3: BookValueQuarter
  Q4: BookValueQuarter
  transaction_summary: TransactionSummary
}

type BookValue = {
  [year: number]: BookValueYear
}

//////////////////////////////////////////////////////////////////////
//// Functions
//////////////////////////////////////////////////////////////////////
function assertNumber(obj: { [key: string]: any }) {
  const key = Object.keys(obj)[0]

  if (!validNumber(obj[key])) {
    throw new Error(`Invalid argument: ${key} must be a number`)
  }
}

function validNumber(value: any): boolean {
  return typeof value === 'number' && value === value
}

const generateMoney = (amount: number) => toMoney(amount, 'USD')

function computeBalanceDelta(running_balance: number, transaction_summary: TransactionSummary): number {
  // const addition = sum([running_balance, transaction_summary.called_capital])
  // const subtraction = difference([
  //   addition,
  //   transaction_summary.distributed_capital,
  //   transaction_summary.distributed_carried_interest,
  //   transaction_summary.distributed_other_fees,
  // ])

  return sum([
    running_balance,
    transaction_summary.total_cash,
    transaction_summary.called_management_fees,
    transaction_summary.called_other_fees,
    transaction_summary.realized_gain,
    transaction_summary.distributed_interest_earned,
    transaction_summary.distributed_carried_interest,
    transaction_summary.distributed_other_fees,
  ])
}

const transformTransfer = (transfer) => {
  const data = {}
  const negative_values = [
    'called_management_fees',
    'called_other_fees',
    'distributed_other_fees',
    'distributed_carried_interest',
  ]
  const transaction_summary_keys = Object.keys(initialTransactionSummary())
  transaction_summary_keys.forEach((key) => {
    data[key] = transfer[key] || initialMoney

    // convert fees to negative values
    negative_values.forEach((fee_key) => {
      if (key === fee_key) {
        data[key] = times(data[key], -1)
      }
    })
  })
  return data
}

//////////////////////////////////////////////////////////////////////
//// Initial states
//////////////////////////////////////////////////////////////////////
const initialTransactionSummary: TransactionSummary = () => ({
  called_capital: generateMoney(0),
  called_management_fees: generateMoney(0),
  called_other_fees: generateMoney(0),
  total_called: generateMoney(0),
  distributed_capital: generateMoney(0),
  distributed_return_of_capital: generateMoney(0),
  distributed_preferred_return: generateMoney(0),
  distributed_profit: generateMoney(0),
  distributed_interest_earned: generateMoney(0),
  distributed_carried_interest: generateMoney(0),
  distributed_other_fees: generateMoney(0),
  realized_gain: generateMoney(0),
  total_distributed: generateMoney(0),
  total_cash: generateMoney(0),
})
const intialBookValueQuarter: BookValueQuarter = () => ({
  beginning_balance: generateMoney(0),
  ending_balance: generateMoney(0),
  transaction_summary: initialTransactionSummary(),
})
const intialBookValueYear: BookValueYear = () => ({
  beginning_balance: generateMoney(0),
  ending_balance: generateMoney(0),
  Q1: intialBookValueQuarter(),
  Q2: intialBookValueQuarter(),
  Q3: intialBookValueQuarter(),
  Q4: intialBookValueQuarter(),
  transaction_summary: initialTransactionSummary(),
})

//////////////////////////////////////////////////////////////////////
//// Main
//////////////////////////////////////////////////////////////////////
export const generateBookValue = (
  transfers: Transfer[],
  options: { startYear: number; endYear: number },
): BookValue => {
  let { startYear, endYear } = options

  assertNumber({ startYear })
  assertNumber({ endYear })

  if (startYear > endYear) {
    throw new Error('Invalid argument: startYear must be less than or equal to endYear')
  }

  if (transfers.length > 0) {
    startYear = Math.min(new Date(transfers[0].date).getFullYear(), startYear)
    endYear = Math.max(new Date(transfers[transfers.length - 1].date).getFullYear(), endYear)
  }

  const book_value: BookValue = {}

  for (let year = startYear; year <= endYear; year++) {
    book_value[year] = intialBookValueYear()
  }

  // set initial state to current year's book value if its null
  const currentYear = new Date().getFullYear()
  if (!book_value[currentYear]) {
    book_value[currentYear] = intialBookValueYear()
  }

  // compute transaction summaries
  const transaction_summary_keys = Object.keys(initialTransactionSummary())
  transfers.forEach((transfer) => {
    const transformed_transfer = transformTransfer(transfer)
    const year = new Date(transfer.date).getFullYear()
    const quarter = `Q${Math.floor(new Date(transfer.date).getMonth() / 3) + 1}`

    transaction_summary_keys.forEach((key) => {
      if (book_value[year][quarter].transaction_summary.hasOwnProperty(key)) {
        const quarterCurrentValue = book_value[year][quarter].transaction_summary[key]
        const quarterTransactionValue = transformed_transfer[key]
        book_value[year][quarter].transaction_summary[key] = sum([quarterCurrentValue, quarterTransactionValue])
      }
      if (book_value[year].transaction_summary.hasOwnProperty(key)) {
        const quarterCurrentValue = book_value[year].transaction_summary[key]
        const quarterTransactionValue = transformed_transfer[key]
        book_value[year].transaction_summary[key] = sum([quarterCurrentValue, quarterTransactionValue])
      }
    })
  })

  // compute balances
  let running_balance = generateMoney(0)
  const years = Object.keys(book_value).sort((a, b) => Number(a) - Number(b))
  years.forEach((year) => {
    for (let quarter_index = 1; quarter_index <= 4; quarter_index++) {
      const quarter = `Q${quarter_index}`
      const transaction_summary = book_value[year][quarter].transaction_summary

      book_value[year][quarter].beginning_balance = running_balance
      running_balance = computeBalanceDelta(running_balance, transaction_summary)
      book_value[year][quarter].ending_balance = running_balance
    }
    book_value[year].beginning_balance = book_value[year]['Q1'].beginning_balance
    book_value[year].ending_balance = book_value[year]['Q4'].ending_balance
  })

  return book_value
}

// This is to get the total values of the transaction summary of all years. So Quarters are not calculated here.
export const generateSinceInceptionBookValue = (bookValue: BookValue): BookValueYear => {
  const since_inception = intialBookValueYear()
  if (!bookValue) return since_inception

  const transaction_summary_keys = Object.keys(initialTransactionSummary())
  Object.values(bookValue).forEach((bookValueYear) => {
    transaction_summary_keys.forEach((key) => {
      if (since_inception.transaction_summary.hasOwnProperty(key)) {
        const currentValue = since_inception.transaction_summary[key]
        const transactionValue = bookValueYear.transaction_summary[key]
        since_inception.transaction_summary[key] = sum([currentValue, transactionValue])
      }
    })
  })

  const startYear = Math.min(...Object.keys(bookValue).map((year) => Number(year)))
  const endYear = Math.max(...Object.keys(bookValue).map((year) => Number(year)))
  since_inception.beginning_balance = bookValue[startYear].Q1.beginning_balance
  since_inception.ending_balance = bookValue[endYear].Q4.ending_balance

  return since_inception
}

export const generateYearToDateBookValue = (bookValue: BookValue): BookValueYear => {
  const currentYear = new Date().getFullYear()
  const intial = intialBookValueYear()
  if (!bookValue) return intial

  return bookValue[currentYear] || intial
}
