import { Column } from '../v-table'

export const distributionMethodColumns: Column[] = [
  {
    key: 'investor.disbursement_method_confirmed_at',
    name: 'Payment Method Confirmed At',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_method confirmed by',
    name: 'Payment Method Confirmed By',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_name',
    name: 'Bank Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_address',
    name: 'Bank Address',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_routing_number',
    name: 'Bank Routing Number',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_account_number',
    name: 'Bank Account Number',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_swift_code',
    name: 'Bank Swift Code',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_account_name',
    name: 'Account Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_for_further_credit_to',
    name: 'For Further Credit To',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_check_receiver_name',
    name: 'Check Receiver Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_other_details',
    name: 'Other Payment Method Details',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
]
