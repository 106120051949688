<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { VSection } from '@/modules/shared/components'
import { OwnershipTransfer, ownership_transfer_initial_state } from '../stores/ownership-transfer-store'

const { n } = useExtendedI18n()

const props = withDefaults(
  defineProps<{
    ownership_transfer?: OwnershipTransfer
  }>(),
  {
    ownership_transfer: { ...ownership_transfer_initial_state },
  },
)
</script>

<template>
  <div>
    <div
      class="-mx-6 -mt-6 mb-3 border-b-[1px] border-gray-300 px-6 py-3 text-lg font-semibold"
      v-if="ownership_transfer.entity?.name"
    >
      Transfer in {{ ownership_transfer.entity.name }}
    </div>
    <VSection label="Seller Capital Amount Summary">
      <table class="seller-summary w-full">
        <thead>
          <tr>
            <th></th>
            <th>Before</th>
            <th>After</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="font-semibold">Units</td>
            <td>
              {{ n(ownership_transfer.seller_commitment.capital_committed, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.seller_commitment.after_transfer.capital_committed, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Ownership</td>
            <td>
              {{ n(ownership_transfer.seller_commitment.capital_called, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.seller_commitment.after_transfer.capital_called, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Management Fees</td>
            <td>
              {{ n(ownership_transfer.seller_commitment.management_fees, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.seller_commitment.after_transfer.management_fees, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Other Fees</td>
            <td>
              {{ n(ownership_transfer.seller_commitment.other_fees, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.seller_commitment.after_transfer.other_fees, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Total Called</td>
            <td>
              {{ n(ownership_transfer.seller_commitment.total_called, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.seller_commitment.after_transfer.total_called, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Commitment Remaining</td>
            <td>
              {{ n(ownership_transfer.seller_commitment.commitment_remaining, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.seller_commitment.after_transfer.commitment_remaining, 'currency') }}
            </td>
          </tr>
        </tbody>
      </table>
    </VSection>
    <VSection label="Disbursement to seller">
      <table class="buyer-summary">
        <tr>
          <td class="font-semibold">Total Capital</td>
          <td>
            {{ n(ownership_transfer.disbursement.capital, 'currency') }}
          </td>
        </tr>
        <tr>
          <td class="font-semibold">Carried Interest</td>
          <td>
            {{ n(ownership_transfer.disbursement.carried_interest, 'currency') }}
          </td>
        </tr>
        <tr>
          <td class="font-semibold">Other Fees</td>
          <td>
            {{ n(ownership_transfer.disbursement.other_fees, 'currency') }}
          </td>
        </tr>
        <tr>
          <td class="font-semibold">Net Amount to seller</td>
          <td>
            {{ n(ownership_transfer.disbursement.total_disbursed, 'currency') }}
          </td>
        </tr>
      </table>
    </VSection>
    <VSection label="Buyer Capital Amount Summary" class="!mb-0">
      <table class="seller-summary w-full">
        <thead>
          <tr>
            <th></th>
            <th>Before</th>
            <th>After</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="font-semibold">Commitment</td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.capital_committed, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.after_transfer.capital_committed, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Capital Called</td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.capital_called, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.after_transfer.capital_called, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Management Fees</td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.management_fees, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.after_transfer.management_fees, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Other Fees</td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.other_fees, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.after_transfer.other_fees, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Total Called</td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.total_called, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.after_transfer.total_called, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Commitment Remaining</td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.commitment_remaining, 'currency') }}
            </td>
            <td>
              {{ n(ownership_transfer.buyer_commitment.after_transfer.commitment_remaining, 'currency') }}
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Cost/Tax Basis</td>
            <td></td>
            <td>
              {{ n(ownership_transfer.disbursement.capital, 'currency') }}
            </td>
          </tr>
        </tbody>
      </table>
    </VSection>
  </div>
</template>

<style>
table.seller-summary td,
table.seller-summary th,
table.buyer-summary td {
  @apply whitespace-nowrap pb-2 pr-5 text-left first:w-[1%];
}
</style>
