import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Commitment = {
  _cid: CID
  _key: string
  id: string
  has_ownership_transfers: boolean
  has_scheduled_calls: boolean
  has_outstanding_invoices: boolean
  investor_cid: CID
  investor_id: string
  investor_type: string
  investor: {
    _cid: ID
    id: ID
    name: string
    ruby_type: string
  }
}

type XInvestor = {
  _cid: CID
  _key: string
  id: string
  name: string
  commitments: Commitment[]
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type CommitmentMap = Map<CID, Commitment>

export const useCommitmentStore = () => {
  const store = defineStore('investing/commitmentStore', () => {
    const route = useRoute()
    const notificationStore = useNotificationStore()
    const baseUrl = computed(() => `/${route.params.slug}/investing`)

    const items = ref<CommitmentMap>(new Map())
    const commitments = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

    const fetchCommitments = async (investable_type, investable_id) => {
      const { data, error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/commitments`)
        .get()
        .json<{ data: Commitment[] }>()

      if (error.value) {
        // TODO: handle error (e.g., display a message to the user)
        console.error(error.value)
        return
      }

      clearItems(items)
      addItems(
        items,
        data.value.data.map((commitment) => ({ ...commitment, _cid: commitment.id })),
      )
    }

    type AddCommitmentPayload = {
      amount: number
      carried_interest_percentage: number
      date: string
      investor_id: CID
      management_fee_percentage: number
      preferred_return_percentage: number
    }

    const addCommitment = async (commitment: AddCommitmentPayload, investable_type, investable_id) => {
      const { data, error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/commitment/add`)
        .post({ commitment })
        .json<{}>()

      if (error.value) {
        // TODO: handle error (e.g., display a message to the user)
        console.error(error.value)
        notificationStore.enqueue('error', 'Failed to add investor')
        return
      }

      await fetchCommitments(investable_type, investable_id)
      notificationStore.enqueue('success', 'Investor was successfully added')
    }

    const removeCommitment = async (commitment_id: ID, investable_type, investable_id) => {
      const { data, error } = await useFetch(
        `${baseUrl.value}/${investable_type}/${investable_id}/commitment/${commitment_id}/remove`,
      )
        .delete()
        .json<{}>()

      if (error.value) {
        // TODO: handle error (e.g., display a message to the user)
        console.error(error.value)
        notificationStore.enqueue('error', data.value.data)
        return
      }
      await fetchCommitments(investable_type, investable_id)
    }

    type TransferCommitmentPayload = {
      commitment_id: ID
      from_investor_id: CID
      to_investor_id: CID
    }

    const transferCommitment = async (
      commitment_id: ID,
      transfer: TransferCommitmentPayload,
      investable_type,
      investable_id,
    ) => {
      await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/commitment/${commitment_id}/transfer`)
        .post(transfer)
        .json<{}>()
      await fetchCommitments(investable_type, investable_id)
      notificationStore.enqueue('success', 'Investor commitment was successfully trasferred')
    }

    type UpdateCommitmentPayload = {}

    const updateCommitment = async (
      commitment_id: ID,
      commitment: UpdateCommitmentPayload,
      investable_type,
      investable_id,
    ) => {
      await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/commitment/${commitment_id}`)
        .put({ commitment })
        .json<{}>()
      await fetchCommitments(investable_type, investable_id)
      notificationStore.enqueue('success', 'Investor was successfully modified')
    }

    const updateOwnership = async (commitment_id: ID, commitment, investable_type, investable_id) => {
      await useFetch(
        `${baseUrl.value}/${investable_type}/${investable_id}/commitment/${commitment_id}/update_ownership`,
      )
        .post({ commitment })
        .json<{}>()
      await fetchCommitments(investable_type, investable_id)
      notificationStore.enqueue('success', 'Investor was successfully modified')
    }

    return {
      items,
      commitments,

      fetchCommitments,
      addCommitment,
      removeCommitment,
      transferCommitment,
      updateCommitment,
      updateOwnership,
    }
  })

  return store()
}
