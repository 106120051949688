import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useFetch } from '@/modules/shared/composables/use-fetch'

// AKA "Group", but group is reserved in Ruby
type Workspace = {
  id: string | number
  name: string
  subdomain: string
  //
  options: {
    enable_investor_aml_verification: boolean
    is_company_list_enabled: boolean
    is_setup_mode_enabled: boolean
    hide_investors_from_investors: boolean
    show_chapters_to_investors: boolean
    can_access_rails: boolean
    is_aml_enabled: boolean
  }
  //
  entity_count: number
  has_managed_entities: boolean
  individual_count: number
  fund_count: number
  spv_count: number
}

const initialState: Workspace = {
  id: null,
  name: null,
  subdomain: null,
  options: {
    enable_investor_aml_verification: false,
    is_company_list_enabled: false,
    is_setup_mode_enabled: false,
    hide_investors_from_investors: true,
    show_chapters_to_investors: false,
    can_access_rails: false,
    is_aml_enabled: false,
  },
  entity_count: 0,
  has_managed_entities: false,
  individual_count: 0,
  fund_count: 0,
  spv_count: 0,
}

export const useWorkspaceStore = defineStore('workspaceStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}`)
  const current = ref({ ...initialState })
  const hidden_keys = {
    elpiscapital: [
      'investments.cash_position',
      'investments.initial_value',
      'investments.roi',
      'investments.moic',
      'investments.initial_price',
      'invesments.initial_shares',
      'invesments.initial_capital',
      'investments.distributions',
      'investments.accrued_interest',
    ],
  }

  const fetchCurrent = async (slug?: string) => {
    const url = slug ? `/${slug}` : baseUrl.value
    const { data } = await useFetch(`${url}/workspace`).get().json<{ data: Workspace }>()
    current.value = data.value.data
  }

  const reset = () => {
    current.value = { ...initialState }
  }

  const isDataAccessible = (key: string, isAdmin = false) => {
    const subdomain = current?.value?.subdomain

    // If no subdomain is set, assume data is not accessible
    if (!subdomain) return false

    const subdomainKeys = hidden_keys[subdomain]
    if (!subdomainKeys?.length) return true // If no hidden keys for subdomain, data is accessible
    if (!subdomainKeys.includes(key)) return true

    return false
  }

  return {
    hidden_keys,
    current,
    fetchCurrent,
    isDataAccessible,
    reset,
  }
})
