<script setup lang="ts">
import { format } from 'date-fns'
import { get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'
import EntityPortfolioCharts from '@/modules/investing/components/entities/portfolio/charts.vue'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import {
  Company,
  CompanyExpanded,
  useEntityStore,
  IndustryExpanded,
  TermSheetExpanded,
} from '@/modules/investing/stores/better-entity-store'
import {
  VSection,
  VStatsHero,
  VStats,
  VTable,
  VAvatar,
  VDropdownImproved,
  VButton,
  VIcon,
  VTextField,
} from '@/modules/shared/components'
import { initialMoney, toNumber, sumMoney } from '@/modules/shared/utils/money'
import { rails_url } from '@/modules/shared/utils/rails'
import { moic, roi } from '@/modules/shared/utils/reporting'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'

///////////////////////////////////////////////////////////////////////////////
// Filters
///////////////////////////////////////////////////////////////////////////////

const investmentTypes = {
  equity: 'Equity',
  convertible_note: 'Convertible Note',
  revenue_share: 'Revenue Share',
  safe_note: 'Safe Note',
  loan: 'Loan',
  option: 'Option',
  warrant: 'Warrant',
}

const asOfDate = ref(format(new Date(), 'yyyy-MM-dd'))

//

const selectedCompanyIds = ref([])

const companyOptions = computed(() => {
  return entityStore.listCompanies.map((company) => {
    return { label: company.name, value: company.id }
  })
})

const selectedIndustryIds = ref([])

const industryOptions = computed(() => {
  return entityStore.listIndustries
    .map((industry) => {
      return { label: industry.name, value: industry.id }
    })
    .concat([{ label: 'Other', value: null }])
})

const selectedInvestmentTypes = ref([])

const investmentTypeOptions = computed(() => {
  return Object.keys(investmentTypes).map((key) => {
    return { label: investmentTypes[key], value: key }
  })
})

const getFilterLabel = (filter: string[], total: number) => {
  if (filter.length === total) return 'All'
  return `${filter.length} selected`
}

const selectAllFilter = () => {
  selectedCompanyIds.value = companyOptions.value.map((opt) => opt.value)
  selectedIndustryIds.value = industryOptions.value.map((opt) => opt.value)
  selectedInvestmentTypes.value = investmentTypeOptions.value.map((opt) => opt.value)
  asOfDate.value = format(new Date(), 'yyyy-MM-dd')
}

///////////////////////////////////////////////////////////////////////////////
// Calls
///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()

const getInvestmentCurrencyValues = (acc, record) => {
  const money = {
    ...initialMoney,
    currency: entityStore.entity?.currency || 'USD',
  }

  const investmentCurrencyKeys = [
    'accrued_interest',
    'current_price',
    'current_value',
    'distributed',
    'initial_price',
    'initial_value',
  ]

  acc ||= {}
  const totals = {}
  investmentCurrencyKeys.forEach((key) => {
    acc[key] ||= money
    totals[key] = sumMoney([acc[key], record[key]])
  })

  return totals
}

const getInvestmentNumberValues = (acc, record) => {
  const investmentNumberKeys = ['current_shares', 'initial_shares']

  acc ||= {}
  const totals = {}
  investmentNumberKeys.forEach((key) => {
    acc[key] ||= 0
    totals[key] = parseFloat(acc[key]) + parseFloat((record[key] || 0).toString())
  })

  return totals
}

const filteredAndExpandedInvestments = computed(() => {
  return entityStore.listExpandedInvestmentsForSelectedInvestors.reduce((acc, investment) => {
    if (
      selectedCompanyIds.value.includes(investment.company_id) &&
      selectedIndustryIds.value.includes(investment.industry_id) &&
      selectedInvestmentTypes.value.includes(investment.investment_type)
    ) {
      acc.push(investment)
    }
    return acc
  }, [])
})

const filteredExpandedTermSheets = computed((): TermSheetExpanded[] => {
  const term_sheetsObject = filteredAndExpandedInvestments.value.reduce((acc, investment) => {
    console.log('investment.term_sheet_id', investment.term_sheet_id)
    let term_sheet = acc[investment.term_sheet_id]
    // initialize term_sheet
    if (!term_sheet) {
      term_sheet = { ...investment.term_sheet }
      term_sheet.company = { ...investment.company }
      term_sheet.industry = investment.industry ? { ...investment.industry } : { id: null, name: 'Other' }
      term_sheet.investments = []
      term_sheet.investor = { ...entityStore.entity }
    }

    term_sheet = {
      ...term_sheet,
      ...getInvestmentCurrencyValues(term_sheet, investment),
      ...getInvestmentNumberValues(term_sheet, investment),
    }
    term_sheet.investments.push(investment)

    acc[investment.term_sheet_id] = term_sheet
    return acc
  }, {})

  return Object.values(term_sheetsObject).map((term_sheet: TermSheetExpanded) => {
    return {
      ...term_sheet,
      roi: roi({
        current_value: toNumber(term_sheet.current_value),
        distributed: toNumber(term_sheet.distributed),
        initial_value: toNumber(term_sheet.initial_value),
      }),
    }
  })
})

const filteredExpandedCompanies = computed(() => {
  const companiesObject = filteredExpandedTermSheets.value.reduce((acc, term_sheet) => {
    let company = acc[term_sheet.company_id]
    // initialize company
    if (!company) {
      company = { ...term_sheet.company }
      company.term_sheets = []
    }

    company = {
      ...company,
      ...getInvestmentCurrencyValues(company, term_sheet),
      ...getInvestmentNumberValues(company, term_sheet),
    }
    company.term_sheets.push(term_sheet)

    acc[term_sheet.company_id] = company
    return acc
  }, {})

  return Object.values(companiesObject).map((company: CompanyExpanded) => {
    return {
      ...company,
      roi: roi({
        current_value: toNumber(company.current_value),
        distributed: toNumber(company.distributed),
        initial_value: toNumber(company.initial_value),
      }),
    }
  })
})

const filteredExpandedIndustries = computed((): IndustryExpanded[] => {
  const money = {
    ...initialMoney,
    currency: entityStore.entity?.currency || 'USD',
  }

  const industriesObject = filteredAndExpandedInvestments.value.reduce((acc, investment) => {
    let industry = acc[investment.industry_id]
    // initialize industry
    if (!industry) {
      industry = { ...investment.industry } || { id: null, name: 'Other' }
      industry.current_value = money
      industry.initial_value = money
    }

    // prettier-ignore
    industry.current_price = sumMoney([industry.current_value, investment.current_value])
    industry.initial_value = sumMoney([industry.initial_value, investment.initial_value])

    acc[investment.industry_id] = industry
    return acc
  }, {})

  return Object.values(industriesObject)
})

///////////////////////////////////////////////////////////////////////////////
// Totals
///////////////////////////////////////////////////////////////////////////////

const portfolioTotals = computed(() => {
  const money = {
    ...initialMoney,
    currency: entityStore.entity?.currency || 'USD',
  }

  const currentValue = sumMoney(filteredExpandedCompanies.value.map((company) => company.current_value)) || money
  const distributed = sumMoney(filteredExpandedCompanies.value.map((company) => company.distributed)) || money
  const initialValue = sumMoney(filteredExpandedCompanies.value.map((company) => company.initial_value)) || money

  return {
    accruedInterest: money,
    currentValue: currentValue,
    distributed: distributed,
    initialValue: initialValue,
    moic: moic({
      current_value: toNumber(currentValue, true),
      distributed: toNumber(distributed, true),
      initial_value: toNumber(initialValue, true),
    }),
    roi: roi({
      current_value: toNumber(currentValue, true),
      distributed: toNumber(distributed, true),
      initial_value: toNumber(initialValue, true),
    }),
  }
})

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entityId, slug } = useRoute().params as { entityId: string; slug: string }

const fetch = async () => {
  await entityStore.fetch(entityId, { slug })
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const isAdmin = computed(() => true)

///////////////////////////////////////////////////////////////////////////////
// Rails Utils
///////////////////////////////////////////////////////////////////////////////

const createCompanyUrl = (id: string) => {
  return `${rails_url()}/companies/${id}`
}

const createCompanyTermSheetUrl = (company_id: string, term_sheet_id: string) => {
  return `${rails_url()}/companies/${company_id}/terms/${term_sheet_id}`
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { skeleton, hideSkeleton } = useSkeleton()
const workspaceStore = useWorkspaceStore()

onMounted(async () => {
  await fetch()
  selectAllFilter()
  hideSkeleton()
})
</script>

<template>
  <EntityLayout selectedTab="portfolio">
    <div
      class="mb-10 mt-5 flex flex-wrap items-center gap-3 rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] p-1 py-3 sm:mt-0 sm:flex-row sm:p-3"
    >
      <VDropdownImproved v-model="selectedCompanyIds" aligned="left" :options="companyOptions" class="z-50">
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>Companies: {{ getFilterLabel(selectedCompanyIds, companyOptions.length) }}</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
      <span>where</span>
      <VDropdownImproved v-model="selectedIndustryIds" aligned="left" :options="industryOptions" class="z-50">
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>Industries: {{ getFilterLabel(selectedIndustryIds, industryOptions.length) }}</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
      <VDropdownImproved v-model="selectedInvestmentTypes" aligned="left" :options="investmentTypeOptions" class="z-50">
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>Types: {{ getFilterLabel(selectedInvestmentTypes, Object.values(investmentTypes).length) }}</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
      <VSelectInvestor v-model="entityStore.selectedInvestorKeys" :investors="entityStore.listInvestors" />

      <!-- <span>as of</span>
      <VTextField v-model="asOfDate" type="date" inputClass="py-1.5 text-sm" class="-mt-1" /> -->

      <div class="sm:flex-grow">
        <VButton class="ml-auto mr-0" :click="selectAllFilter">Clear Filters</VButton>
      </div>
    </div>
    <div class="gap-10 sm:flex">
      <VSection label="360° Portfolio overview" class="w-1/2">
        <VStatsHero
          class="mb-6"
          :skeleton="skeleton"
          :currentValue="portfolioTotals.currentValue"
          :initialValue="portfolioTotals.initialValue"
          :hiddenFields="[
            !workspaceStore.isDataAccessible('investments.initial_value', isAdmin) ? 'initial' : null,
            !workspaceStore.isDataAccessible('investments.current_value', isAdmin) ? 'current' : null,
          ]"
        />
        <VStats
          :skeleton="skeleton"
          :stats="[
            {
              colspan: 2,
              label: 'ROI',
              type: 'percent',
              value: portfolioTotals.roi,
              visible: workspaceStore.isDataAccessible('investments.roi', isAdmin),
            },
            {
              colspan: 2,
              label: 'MOIC',
              type: 'multiple',
              value: portfolioTotals.moic,
              visible: workspaceStore.isDataAccessible('investments.moic', isAdmin),
            },
            {
              colspan: 2,
              label: 'Distributed',
              type: 'currency',
              value: portfolioTotals.distributed,
              visible: workspaceStore.isDataAccessible('investments.distributions', isAdmin),
              useCommonCurrency: true,
            },
            {
              colspan: 2,
              label: 'Accrued Interest',
              type: 'currency',
              value: portfolioTotals.accruedInterest,
              visible: workspaceStore.isDataAccessible('investments.accrued_interest', isAdmin),
              useCommonCurrency: true,
            },
          ]"
        />
      </VSection>
      <EntityPortfolioCharts
        :companies="filteredExpandedCompanies"
        :industries="filteredExpandedIndustries"
        :skeleton="skeleton"
      />
    </div>

    <VTable
      :columns="[
        {
          key: 'name',
          name: 'Name',
          type: 'string',
          align: 'left',
          fixed: true,
          sorted: true,
          is_visible: true,
        },
        {
          key: 'investor.name',
          name: 'Investor',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'method',
          name: 'Method',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'initial_value',
          name: 'Initial Value',
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
          is_accessible: workspaceStore.isDataAccessible('investments.initial_value', isAdmin),
        },
        {
          key: 'current_value',
          name: 'Current Value',
          sorted: true,
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
          is_accessible: workspaceStore.isDataAccessible('investments.current_value', isAdmin),
        },
        {
          key: 'distributed',
          name: 'Distributed',
          sorted: true,
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'roi',
          name: 'ROI',
          type: 'percent',
          align: 'center',
          is_visible: true,
          is_accessible: workspaceStore.isDataAccessible('investments.roi', isAdmin),
        },
        {
          key: 'initial_shares',
          name: 'Initial Shares',
          type: 'number',
          align: 'right',
          is_visible: false,
          is_accessible: workspaceStore.isDataAccessible('investments.initial_shares', isAdmin),
        },
        {
          key: 'current_shares',
          name: 'Current Shares',
          type: 'number',
          align: 'right',
          is_visible: false,
          is_accessible: workspaceStore.isDataAccessible('investments.current_shares', isAdmin),
        },
        {
          key: 'initial_price',
          name: 'Initial Price',
          type: 'currency',
          align: 'right',
          is_visible: false,
          is_accessible: workspaceStore.isDataAccessible('investments.initial_price', isAdmin),
        },
        {
          key: 'current_price',
          name: 'Current Price',
          type: 'currency',
          align: 'right',
          is_visible: false,
          is_accessible: workspaceStore.isDataAccessible('investments.current_price', isAdmin),
        },
        {
          key: 'accrued_interest',
          name: 'Accrued Interest',
          type: 'currency',
          align: 'right',
          is_visible: false,
          is_accessible: workspaceStore.isDataAccessible('investments.accrued_interest', isAdmin),
        },
        {
          key: 'industry.name',
          name: 'Industry',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investment_type',
          name: 'Investment Type',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.expiration_date',
          name: 'Expiration Date',
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.annual_dividend_rate',
          name: 'Annual Dividend Rate',
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.board_approval_date',
          name: 'Board Approval Date',
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.type',
          name: 'Type',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.shares_authorized',
          name: 'Shares Authorized',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.seniority',
          name: 'Seniority',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.issue_price',
          name: 'Issue Price',
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.common_conversion_rate',
          name: 'Common Conversion Rate',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.pro_rata',
          name: 'Pro Rata',
          type: 'boolean',
          align: 'center',
          is_visible: false,
        },
        {
          key: 'terms.liquidation_preference',
          name: 'Liquidation Preference',
          type: 'boolean',
          align: 'center',
          is_visible: false,
        },
        {
          key: 'terms.liquidation_preference_multiple',
          name: 'Liquidation Preference Multiple',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.participation_rights',
          name: 'Participation Rights',
          type: 'boolean',
          align: 'center',
          is_visible: false,
        },
        {
          key: 'terms.participation_cap',
          name: 'Participation Cap',
          type: 'boolean',
          align: 'center',
          is_visible: false,
        },
        {
          key: 'terms.participation_cap_multiple',
          name: 'Participation Cap Multiple',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.discount_rate',
          name: 'Discount Rate',
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.first_payment_date',
          name: 'First Payment Date',
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.interest_rate',
          name: 'Interest Rate',
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.interest_type',
          name: 'Interest Type',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.compounding_frequency',
          name: 'Compounding Frequency',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.converts_to',
          name: 'Converts To',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.maturity_date',
          name: 'Maturity Date',
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.annual_share_of_revenue',
          name: 'Annual Share of Revenue',
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.minimum_annual_return_percentage',
          name: 'Minimum Annual Return Percentage',
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.term_value',
          name: 'Term Value',
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.term_units',
          name: 'Term Units',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.payment_frequency',
          name: 'Payment Frequency',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.option_type',
          name: 'Option Type',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.shares_allocated',
          name: 'Shares Allocated',
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.exercise_price',
          name: 'Exercise Price',
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.vesting_period_months',
          name: 'Vesting Period Months',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.cliff_months',
          name: 'Cliff Months',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.after_cliff_vest_months',
          name: 'After Cliff Vest Months',
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.vest_start_date',
          name: 'Vest Start Date',
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.expiration_period_years',
          name: 'Expiration Period Years',
          type: 'number',
          align: 'right',
          is_visible: false,
        },
      ]"
      :items="filteredExpandedCompanies"
      :name="`portfolio`"
      :skeleton="skeleton"
      sub_item_key="term_sheets"
      :slots="['name']"
      :expand="true"
    >
      <template #name="{ item: company }">
        <div class="flex items-center gap-1">
          <VAvatar
            :image="company.logo_url ? decodeURIComponent(company.logo_url) : null"
            :initials="company.initials"
            size="sm"
          />
          <a class="hyperlink" :href="createCompanyUrl(company.id)">
            {{ company.name }}
          </a>
        </div>
      </template>
      <template #subgroup.name="{ item: term_sheet }">
        <div class="inline-flex items-center space-x-2">
          <a class="hyperlink" :href="createCompanyTermSheetUrl(term_sheet.company_id, term_sheet.id)" v-if="isAdmin">
            {{ term_sheet.name }}
          </a>
          <span v-else>{{ term_sheet.name }}</span>
          <span class="text-gray-500">/</span>
          <span>{{ term_sheet.investor.name }}</span>
        </div>
      </template>
    </VTable>
  </EntityLayout>
</template>
