<script setup lang="ts">
import { format } from 'date-fns'
import { ref, watch } from 'vue'
import { VTextField, VButton } from '@/modules/shared/components'
import { Distribution } from '../../../stores/better-entity-store'

const props = defineProps<{
  distribution: Distribution
  onSubmit: any
}>()

const initialState = {
  id: props.distribution?.id,
  paid_date: format(new Date(), 'yyyy-MM-dd'),
}
const distribution_form = ref({ ...initialState })

const markAsPaid = async () => {
  await props.onSubmit(distribution_form.value)
}

watch(
  () => props.distribution,
  (v) => {
    if (v) {
      distribution_form.value = { ...initialState, id: props.distribution.id }
    }
  },
)
</script>

<template>
  <div class="flex h-full flex-col gap-6">
    <div class="flex-grow">
      <VTextField v-model="distribution_form.paid_date" label="Date" property="date" type="date_without_timezone" />
    </div>
    <VButton :click="markAsPaid" variant="primary" size="lg" class="w-full">Mark as paid</VButton>
  </div>
</template>
