import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////
type Company = {
  _cid: CID
  _key: string
  id: string
  name: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type CompanyMap = Map<CID, Company>

export const useCompanyStore = defineStore('investing/companyStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}`)

  const items = ref<CompanyMap>(new Map())
  const companies = computed(() => Array.from(items.value.values()))

  const fetchCompanies = async () => {
    let url = `${baseUrl.value}/companies`

    const { data, error } = await useFetch(url).get().json<{ data: Company[] }>()

    if (error.value) {
      notificationStore.enqueue('error', 'Companies failed to load')
      return
    }

    clearItems(items)
    addItems(items, data.value.data)
  }

  const realizeLoss = async (company_id, investment_id = null) => {
    const { data, error } = await useFetch(`${baseUrl.value}/company/${company_id}/realize_loss`)
      .post({ investment_id })
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  const undoRealizeLoss = async (company_id, investment_id = null) => {
    const { data, error } = await useFetch(`${baseUrl.value}/company/${company_id}/undo_realize_loss`)
      .post({ investment_id })
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  return {
    items,
    companies,

    fetchCompanies,
    realizeLoss,
    undoRealizeLoss,
  }
})
