import { Column } from '../v-table'

export const addressColumns: Column[] = [
  {
    key: 'investor.address.phone',
    name: 'Phone Number',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.line1',
    name: 'Line 1',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.line2',
    name: 'Line 2',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.city',
    name: 'City',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.state',
    name: 'State',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.zip',
    name: 'Postal Code',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.country',
    name: 'Country',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
]
