<script setup lang="ts">
import { endOfDay } from 'date-fns'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { VButton, VIcon } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { locateData } from '@/modules/shared/utils/data'
import { rails_url } from '@/modules/shared/utils/rails'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import CallsList from '../components/calls-list.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import { useCallStore } from '../stores/call-store'
import { useInvestingVehicleStore } from '../stores/vehicle-store'
import { getChartData, transformEntity } from '../utils/entity'
import { useEntityStore } from '../stores/better-entity-store'

const route = useRoute()

///////////////////////////////////////////////////////////////////////////////

const currentEntity = ref(null)
const investorStore = useInvestorStore()
const entityStore = useEntityStore()

const vehicleStore = useInvestingVehicleStore()
const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin' || true)

const selectedKeys = computed(() =>
  investorStore.state.selectedKeys.map((key) => {
    const id = parseInt(key.split(':')[1])
    let type = key.split(':')[0]
    switch (type) {
      case 'spv':
      case 'fund':
      case 'gp':
        type = 'entity'
        break
    }
    return [id, type]
  }),
)
const currentCalls = computed(() => entityStore.listCallsForInvestors(selectedKeys.value))

const chartData = computed(() => {
  const entities = currentEntity.value
    ? transformEntity([currentEntity.value] || [], cid.value, true, investorStore.state.selectedKeys)
    : []
  return getChartData(entities, true, investorStore.state.selectedKeys)
})

const skeleton = ref(true)

onMounted(async () => {
  await investorStore.reset()
  // prettier-ignore
  await Promise.all([
    entityStore.fetch(route.params.entity_id as string, { slug: route.params.slug as string }),
    investorStore.listProfiles(),
  ])

  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <CallsList
      :calls="currentCalls"
      :currency="currentEntity?.currency || 'USD'"
      :disabledColumns="['entity.name']"
      :investable_id="route.params.entity_id as string"
      :investable_type="route.params.entity_type as string"
      :isAdmin="isAdmin"
      :skeleton="skeleton"
      :chartData="chartData"
    >
      <template #actions>
        <RouterLink :to="{ name: 'investing.capital-call.new' }">
          <VButton size="md" variant="v-blue">
            <div class="mr-1 flex items-center space-x-2">
              <div><VIcon name="plus" /></div>
              <div>Call capital</div>
            </div>
          </VButton>
        </RouterLink>
      </template>
    </CallsList>
  </TheLayout>
</template>
